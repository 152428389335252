import api, { responseErrorCheck } from './api'
import TmrBaseResource from './TmrBaseResource'
import { TmrZone } from './types'

export default class Zones extends TmrBaseResource {
  static endpoint = '/zones'
  static paginateWithURLSearchParams(
    searchParams: URLSearchParams,
    paginationConfig = null,
    requestConfig = undefined
  ): Promise<TmrZone[]> {
    const paginationParams = paginationConfig || TmrBaseResource.defaultPaginationConfig
    for (const [key, value] of Object.entries(paginationParams)) {
      searchParams.append(key, String(value))
    }

    return api.get<TmrZone[]>(`${this.endpoint}`, searchParams, requestConfig).then(responseErrorCheck)
  }
}
