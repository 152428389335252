import React, { Component } from 'react'
import { StatusLabel } from 'components'
import { T, __ } from 'translations/i18n'
import { OperationConfigType } from 'shared/RemoteConfig'

interface Props {
  status: string
  width?: number
  type?: OperationConfigType
}

export default class ParcelStatus extends Component<Props> {
  statuses = {
    DRAFT: __(T.labels.draft),
    READY_TO_SEND: __(T.labels.ready_to_send),
    SEND_AWAIT: __(T.labels.send_await),
    OUTBOUND_ERROR: __(T.labels.outbound_error),
    SENT: __(T.labels.sent),
    IN_TRANSIT: __(T.labels.in_transit),
    IN_INBOUND: __(T.labels.in_inbound),
    RECEIVE_AWAIT: __(T.labels.receive_await),
    READY_TO_RECEIVE: __(T.labels.ready_to_receive),
    INBOUND_ERROR: __(T.labels.inbound_error),
    RECEIVED: __(T.labels.received),
  }

  getParcelState = () => {
    const { status, type } = this.props
    if (!status && type === 'inbound') return 'READY_TO_RECEIVE'
    if (!status && type === 'outbound') return '---'
    return status
  }

  getStatusLabelText = (): string => {
    const { status } = this.props
    return this.statuses[this.getParcelState()] ?? status ?? ''
  }

  render() {
    const { width } = this.props

    return <StatusLabel width={width} status={this.getParcelState()} text={this.getStatusLabelText().toUpperCase()} />
  }
}
