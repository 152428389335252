import React from 'react'
import { Box } from 'components'
import styled from '@emotion/styled'

export default function ItemStatusLabel({
  value,
  status,
  style,
  maxWidth,
}: {
  value: string
  status: 'ignore' | 'warning' | 'error'
  style?: React.CSSProperties
  maxWidth?: number
}) {
  const getColor = () => {
    switch (status) {
      case 'warning':
        return { backgroundColor: '#FFEFC6', color: '#D6A52A' }
      case 'error':
        return { backgroundColor: '#FFD4CB', color: '#EF4141' }
      case 'ignore':
        return { backgroundColor: '#EDEDED' }
      default:
        return { backgroundColor: '#EDEDED' }
    }
  }

  return (
    <Box
      row
      mr={15}
      style={{
        borderRadius: 5,
        backgroundColor: getColor().backgroundColor,
        maxWidth: maxWidth,
        ...style,
      }}
      pv={5}
      ph={10}
    >
      <SmallValue style={{ color: getColor().color }}>{value}</SmallValue>
    </Box>
  )
}

const SmallValue = styled.label`
  font-weight: 900;
  font-size: 16px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`
