import React, { Component } from 'react'
import { Form, Page, Box, Icons } from 'components'
import { getLocationState, getMatchParams, navigate } from 'shared/router'
import { ShippingParcelCreateRequest, TmrZone } from 'api/types'
import { showToast, uuid } from 'shared/utils'
import Zones from 'api/Zones'
import { T, __ } from 'translations/i18n'
import RemoteConfig, { InboundConfig } from 'shared/RemoteConfig'
import InboundShipments from 'api/InboundShipments'

interface State {
  zones?: TmrZone[]
  initialValues?: any
  fromShipment: boolean
  loading: boolean
}
export default class InboundCreate extends Component<{}, State> {
  operation = RemoteConfig.getOperationConfig<InboundConfig>(getMatchParams(this.props).configCode)

  state: State = {
    zones: [],
    fromShipment: getLocationState(this.props)?.fromShipment,
    initialValues: {
      destination: getLocationState(this.props)?.destination,
      shipmentCode: getLocationState(this.props)?.shipmentCode,
    },
    loading: this.operation.hideDestinationInput !== 'yes',
  }

  componentDidMount() {
    this.operation.hideDestinationInput !== 'yes' && this.fetchZones()
  }

  fetchZones = async () => {
    const { initialValues } = this.state
    try {
      const zones = ((await Zones.search<TmrZone>({ limit: 500 })) ?? []).filter((z) => z.zoneType === 'STOCK')
      if (zones.length === 1 && !initialValues.destination) {
        initialValues.destination = zones[0]
      }
      this.setState({ zones: zones, initialValues, loading: false })
    } catch (err) {
      showToast({
        title: __(T.error.error),
        description: err?.message ?? 'Generic error',
        status: 'error',
      })
    }
  }

  submit = async (data) => {
    const { fromShipment } = this.state
    if (this.operation.hideDestinationInput !== 'yes' && !data.destination) {
      showToast({
        title: __(T.error.error),
        description: __(T.error.destination_field_required),
        status: 'error',
      })
      return
    }

    let state = {
      shipmentCode: data.shipmentCode ?? uuid(),
      parcelCode: data.parcelCode ?? uuid(),
      destination: data.destination ?? undefined,
      fromShipment,
    }
    try {
      if (this.operation?.id) {
        const request: ShippingParcelCreateRequest = {
          configurationId: this.operation.id,
          destinationPlaceCode: data.destination?.code || undefined,
          checklistType: 'ITEMS',
        }
        if (data.shipmentCode) request.shippingCode = data.shipmentCode
        if (data.parcelCode) request.parcelCode = data.parcelCode

        const response = await InboundShipments.createParcel(request)
        if (!response || !response?.header?.parcelCode) throw new Error('No Parcel created') // Never shown to user

        state = {
          shipmentCode: response?.header?.shippingCode,
          parcelCode: response?.header?.parcelCode,
          destination: response?.header?.destinationPlace,
          fromShipment,
        }
        navigate(
          '/inbound/:configCode/reading/:parcelCode',
          { parcelCode: state.parcelCode, configCode: this.operation?.code },
          { state }
        )
      }
    } catch (error) {
      showToast({ status: 'error', title: error.message })
    }
  }

  onBackPress = () => {
    if (this.state.fromShipment) {
      navigate(`/inbound/:configCode/:shippingCode/parcels`, {
        configCode: this.operation?.code,
        shippingCode: this.state.initialValues?.shipmentCode,
      })
      return
    }
    if (this.operation.hasChecklist === 'yes' || this.operation.inboundMode === 'shipment') {
      navigate(`/inbound/:configCode`, { configCode: this.operation?.code })
    } else {
      navigate(`/`)
    }
  }

  render() {
    const { zones, initialValues, loading } = this.state

    return (
      <Page title={this.operation?.description ?? 'Inbound'} onBackPress={this.onBackPress}>
        <Page.Content bgGrey>
          <Box flex center loading={loading}>
            <Form
              schema={[
                {
                  label: __(T.misc.shipment_code_auto),
                  name: 'shipmentCode',
                  image: <Icons.Barcode />,
                  focus: !initialValues.shipmentCode,
                  hide: this.state.fromShipment || this.operation.hideShipmentInput === 'yes',
                },
                {
                  label: __(T.misc.parcel_code_auto),
                  name: 'parcelCode',
                  image: <Icons.Barcode />,
                  focus: initialValues.shipmentCode,
                  hide: this.operation.hideParcelInput === 'yes',
                },
                {
                  placeholder: __(T.misc.destination),
                  name: 'destination',
                  required: this.operation.hideDestinationInput !== 'yes',
                  type: 'select',
                  options: zones,
                  config: { value: 'id', label: 'description', secondaryLabel: 'code' },
                  hide: this.state.fromShipment || this.operation.hideDestinationInput === 'yes',
                },
              ]}
              initialValues={initialValues}
              submitText={__(T.misc.next)}
              onSubmit={this.submit}
              width={400}
            />
          </Box>
        </Page.Content>
      </Page>
    )
  }
}
