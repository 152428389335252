import React, { Component } from 'react'
import { Box, Input, Modal } from 'components'
import { Spacer } from 'components/Various'
import { closeModal, showToast, sleep } from 'shared/utils'
import Button from 'components/Button'
import { T, __ } from 'translations/i18n'
import Icons from 'components/Icons'

interface Props {
  isOpen?: boolean
  style?: any
  label?: string
  onClose?: () => void
  onConfirm?: (emails: string[], notes?: string) => void
  emails?: string[]
}

interface State {
  emails: string[]
  newEmail?: string
  note?: string
  modalVisible: boolean
}

export default class CustomEmailNotesModal extends Component<Props, State> {
  state: State = { emails: this.props.emails ?? [], modalVisible: true }

  addEmail = (email) => {
    if (!this.validateEmail(email)) {
      showToast({ description: __(T.error.email_not_valid), status: 'error' })
      return
    }
    if (this.state.emails.includes(email)) return
    this.state.emails.push(email)
    this.setState({ emails: [...this.state.emails] })
  }

  validateEmail = (mail: string) => {
    // eslint-disable-next-line no-useless-escape
    const mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/
    return mail.match(mailformat)
  }

  confirm = async () => {
    closeModal('emailAndNotesModal')
    await sleep(200)
    this.props.onConfirm && this.props.onConfirm(this.state.emails, this.state.note)
    return 0
  }

  render() {
    const { emails, newEmail, note, modalVisible } = this.state
    const { onClose } = this.props

    return (
      <Modal
        id="emailAndNotesModal"
        size="2xl"
        visible={modalVisible}
        onClose={() => {
          this.setState({ modalVisible: false })
          onClose && onClose()
        }}
      >
        <Box flex row>
          <Box flex ph={20} pv={20}>
            <Modal.Title>{__(T.titles.insert_email)}</Modal.Title>
            <Spacer />
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <Input
                type="email"
                value={newEmail}
                placeholder={__(T.misc.email)}
                onEnter={this.addEmail}
                onChange={(email) => {
                  this.setState({ newEmail: email })
                }}
                style={{ flex: 1 }}
              />
              <Button title={__(T.misc.add)} onClick={() => this.addEmail(newEmail)} style={{ marginLeft: 10 }} />
            </div>
            <div style={{ marginTop: 20 }}>
              {emails.map((email) => (
                <div
                  style={{
                    border: '2px solid #eee',
                    display: 'flex',
                    flexDirection: 'row',
                    borderRadius: 10,
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    paddingLeft: 20,
                    paddingRight: 6,
                    marginTop: 6,
                  }}
                >
                  {email}
                  <Icons.Close
                    style={{ margin: 10, cursor: 'pointer', width: 24 }}
                    onClick={() => this.setState({ emails: this.state.emails.filter((el) => el !== email) })}
                  />
                </div>
              ))}
            </div>
            <Spacer />
            <Modal.Title>{__(T.titles.insert_note_optional)}</Modal.Title>
            <Spacer />
            <Input
              value={note}
              placeholder="Note"
              onEnter={this.addEmail}
              onChange={(notes) => {
                this.setState({ note: notes })
              }}
              style={{ flex: 1 }}
            />
            <Spacer />
            <Spacer />
            <Button
              title={__(T.misc.confirm)}
              onClick={emails.length === 0 ? undefined : this.confirm}
              variant={emails.length === 0 ? 'default' : 'primary'}
            />
          </Box>
        </Box>
      </Modal>
    )
  }
}
