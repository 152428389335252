import React, { useEffect, useState } from 'react'
import styled from '@emotion/styled'
import { Icons, Image } from 'components'

const errorColor = '#dd4949'

interface InputProps extends Partial<HTMLInputElement> {
  onEnter?: (value: string) => void
  inputRef?: React.RefObject<HTMLInputElement>
  error?: string
  barcode?: boolean
  autoFocus?: boolean
  style?: React.CSSProperties | any
  image?: any
  onChange?: (value: string) => void
  startFocus?: boolean
  label?: string
  debounce?: number
  onFocus?: () => void
  onBlur?: (value: any) => void
}

export default function InputComponent({
  onEnter,
  inputRef = React.createRef<HTMLInputElement>(),
  error,
  barcode,
  autoFocus,
  style,
  image,
  onChange,
  placeholder,
  defaultValue,
  type,
  required,
  startFocus,
  label,
  disabled,
  id,
  onFocus,
  debounce,
  onBlur,
}: InputProps) {
  const [started, setstarted] = useState(false)
  const [value, setvalue] = useState(defaultValue ?? '')
  const [loading, setloading] = useState(false)
  const [cleared, setcleared] = useState(false)
  const [timer, settimer] = useState<any>(null)

  // TODO: handle onBarcodeScan with a debounce

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const clear = () => {
    inputRef!.current!.value = '' as any
    setvalue('')
    onChange && onChange('')
    inputRef!.current!.focus()
  }

  useEffect(() => {
    if (startFocus && !started) {
      setstarted(true)
      inputRef?.current?.focus()
    }
    if (autoFocus) {
      inputRef?.current?.focus()
      inputRef?.current?.addEventListener('blur', () => {
        setTimeout(() => {
          inputRef?.current?.focus()
        }, 300)
      })
    }
    if (!barcode) {
      return
    }
    if (error && !cleared) {
      clear()
      setcleared(true)
    }
    if (!error) {
      setcleared(false)
    }
  }, [error, clear, cleared, setcleared, barcode, inputRef, autoFocus, startFocus, started])

  const handleKeyUp = async (e: React.KeyboardEvent<HTMLInputElement>) => {
    const newValue = (e.target as any).value
    setvalue(newValue)
    if (debounce && e.key !== 'Enter') {
      clearTimeout(timer)
      settimer(
        setTimeout(() => {
          onChange && onChange(newValue)
        }, debounce)
      )
    } else {
      onChange && onChange(newValue)
    }
    if (e.key === 'Enter' && !loading) {
      setloading(true)
      setTimeout(async () => {
        onEnter && (await onEnter(newValue))
        setloading(false)
        inputRef?.current?.focus()
        setcleared(false)
      }, debounce)
    }
  }

  const labelFocus = (e) => {
    inputRef!.current!.focus()
    e.preventDefault()
  }

  const icon = image

  return (
    <div style={style}>
      <Field className="float-label">
        {label && (
          <Label onClick={labelFocus} text={value} disabled={disabled}>
            {label}
          </Label>
        )}
        <InputStyle
          label={label}
          disabled={disabled || loading}
          defaultValue={defaultValue}
          error={error}
          ref={inputRef}
          required={required}
          placeholder={placeholder}
          onKeyUp={handleKeyUp}
          type={type}
          id={id}
          name={id}
          onFocus={onFocus}
          onBlur={onBlur}
        />
        <ImageWrap>
          {icon && typeof icon === 'string' && value.length === 0 && <RightImage src={icon} alt="" />}
          {icon && typeof icon !== 'string' && value.length === 0 && <RightIcon>{icon}</RightIcon>}
          {!loading && (value.length || 0) > 0 && !disabled && (
            <Icons.Close style={{ margin: 10, cursor: 'pointer', width: 24 }} onClick={clear} />
          )}
          {loading && <Icons.Loader style={{ width: 40 }} />}
        </ImageWrap>
      </Field>
      {error && <ErrorStyle>* {error}</ErrorStyle>}
    </div>
  )
}

const InputStyle = styled.input<{ error: any; label?: string }>`
  flex: 1;
  height: ${({ label }) => (label ? `65px` : '65px')};
  background: #ffffff;
  width: 100%;
  // box-shadow: 0px 1px 4px ${({ error }) => (error ? 'rgba(221, 73, 73, 0.15)' : 'rgba(0, 0, 0, 0.15)')};
  border: ${({ error }) => (error ? `2px solid ${errorColor}` : '2px solid #D2D2D2')};
  border-radius: 10px;
  font-style: normal;
  font-weight: normal;
  color: #333333;
  font-size: 22px;
  line-height: 26px;
  padding-left: 13px;
  padding-right: 50px;
  ${({ label }) => (label ? `padding-top: 17px;` : '')}
  :focus {
    box-shadow: 0px 0px 0px 3px rgba(47, 128, 237, 0.5);
    outline: transparent auto 0px;
    border-radius: 10px;
    border: 2px solid #2f80ed;
  }
  :disabled {
    border: 2px solid transparent;
    color: #000000;
  }
`

const ImageWrap = styled.div`
  justify-content: center;
  align-items: center;
  position: absolute;
  margin: auto;
  position: absolute;
  display: flex;
  top: 0;
  bottom: 0;
  right: 10px;
  z-index: 999;
`

const RightImage = styled((props) => <Image {...props} />)`
  margin: 10px;
  cursor: pointer;
  width: 24px;
`

const RightIcon = styled.div`
  svg {
    margin-right: 10px;
    cursor: pointer;
    width: 24px;
  }
`

const ErrorStyle = styled.div`
  margin-left: 10px;
  margin-top: 10px;
  font-weight: bold;
  color: ${errorColor};
`

const activeField = `
  transform: translate(0, 11px) scale(0.75)!important;
  left: 15px;
  color: #454545;
  font-size: 16px;
  font-weight: 700;
  text-align: left;
`

const Field = styled.div`
  position: relative;
  margin: auto;
  flex: 1;
  display: flex;

  :focus-within label {
    ${activeField}
  }
`

const Label = styled.label<{ text?: string; disabled?: boolean }>`
  font-style: normal;
  font-weight: normal;
  font-size: 22px;
  color: ${({ disabled }) => (disabled ? '#000004' : '#666666')};
  line-height: 26px;

  position: absolute;
  left: 22px;
  transform: translate(0, 20px) scale(1);
  transform-origin: top left;
  transition: all 0.2s ease-out;

  ${({ text }) => (text ? activeField : '')}
`
