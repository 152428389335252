import React, { Component } from 'react'
import { Box, Button, Icons, Input, Page, Tab, Table, Section } from 'components'
import { getMatchParams, navigate } from 'shared/router'
import { GroupedShipmentProduct, Session, SessionItem } from 'api/types'
import { showToast } from 'shared/utils'
import { T, __ } from 'translations/i18n'
import Structures from 'shared/Structures'
import Sessions from 'api/Sessions'
import InboundCampionarioProvider from './InboundCampionarioProvider'
import CustomParcelSectionHeader from './CustomParcelSectionHeader'

interface State {
  ddt?: Session
  products?: SessionItem[]
  filter: string
  options: any[]
  loading: boolean
}

export default class InboundCampionarioShipmentDetail extends Component {
  inputRef = React.createRef<HTMLInputElement>()

  state: State = {
    filter: '',
    loading: true,
    options: [
      { value: 'product', label: __(T.misc.product), active: true },
      { value: 'parcel', label: __(T.misc.parcel), active: false },
    ],
  }

  async componentDidMount() {
    const { shippingId } = getMatchParams(this.props)
    try {
      const ddt = await InboundCampionarioProvider.retrieveDDT(shippingId)
      const products: SessionItem[] = []
      ddt?.packs.forEach((pack) => {
        pack.items?.forEach((item) => {
          const product = products.find((p) => p.upc === item.upc)
          if (product) {
            product.quantity++
          } else {
            products.push({ ...item, quantity: 1 })
          }
        })
      })
      this.setState({ ddt, products, loading: false })
    } catch (error) {
      showToast({
        title: __(T.error.error),
        description: error?.message ?? 'Generic error',
        status: 'error',
      })
      this.navigateBack()
    }
  }

  navigateBack = () => navigate('/inbound-campionario')

  setFilter = (filter: string) => this.setState({ filter })

  getFilteredProducts(products: GroupedShipmentProduct[], filter: string) {
    return products.filter(
      (product) =>
        product.product.code.toLowerCase().includes(filter) ||
        product.product.style?.value.toLowerCase().includes(filter) ||
        product.product.material?.value.toLowerCase().includes(filter) ||
        product.product.color?.value.toLowerCase().includes(filter) ||
        product.product.size?.value.toLowerCase().includes(filter)
    )
  }

  changeViews = (option: string) => {
    const { options } = this.state
    options.forEach((opt) => (opt.active = opt.value === option))
    this.inputRef.current!.value! = ''
    this.setState({ options, filter: '' })
  }

  onSearch = () => {}

  newParcel = () => {
    const { ddt } = this.state
    navigate('/inbound-campionario/:shippingId/create', { shippingId: ddt?.id })
  }

  onShipmentConfirm = async () => {
    try {
      const { ddt } = this.state
      if (!ddt) return
      const closed = await Sessions.close(ddt.id)
      if (!closed) {
        throw new Error('')
      }

      showToast({ title: __(T.misc.confirmed), status: 'success' })
      navigate('/inbound-campionario')
    } catch (e) {
      showToast({ title: 'Error during shipment confirm', status: 'error' })
    }
  }

  render() {
    const { ddt, products, options, filter, loading } = this.state
    const parcels = ddt?.packs

    const shippingCode = ddt?.code
    const parcelsCount = parcels?.length?.toString() ?? '0'
    const destinationPlace = ddt?.destinationZone?.description

    const opt = options.find((o: any) => o.active).value

    return (
      <Page
        loading={loading}
        title="Inbound Campionario"
        onBackPress={() => navigate('/inbound-campionario', { configCode: getMatchParams(this.props).configCode })}
        header={{
          details: [
            { label: __(T.misc.parcels), value: parcelsCount },
            { label: __(T.misc.shipment), value: shippingCode },
            { label: __(T.misc.destination), value: destinationPlace },
          ],
        }}
        headerRight={
          <Button onClick={this.onShipmentConfirm} variant="secondary" size="medium">
            {__(T.misc.confirm_shipment)}
          </Button>
        }
      >
        <Page.Content>
          <Box mb={40} row vcenter>
            <Tab
              variant="secondary"
              style={{ border: '2px solid #E0E0E0' }}
              options={options}
              onOptionSelected={this.changeViews}
            />
            <Box ml={20} flex>
              <Input
                placeholder={__(T.placeholder.search_product_parcel)}
                onChange={(f) => this.setState({ filter: f })}
                image={<Icons.Barcode />}
                style={{ width: 350 }}
                onEnter={this.onSearch}
                inputRef={this.inputRef}
              />
            </Box>
            <Button onClick={this.newParcel}>{__(T.misc.new_parcel)}</Button>
          </Box>
          {opt === 'parcel' &&
            parcels
              ?.filter((parcel) => parcel.code.toLowerCase().includes(filter.toLowerCase()))
              ?.map((parcel, index) => (
                <Section
                  key={index}
                  header={<CustomParcelSectionHeader parcel={parcel} />}
                  body={
                    <Table
                      headerStyle={{ position: 'relative' }}
                      style={{ paddingTop: 0 }}
                      showHeader={false}
                      structure={Structures.InboundCampionarioParcelStructure()}
                      loading={loading}
                      data={parcel.items}
                      emptyStyle={{ height: 300 }}
                    />
                  }
                />
              ))}
          {opt === 'product' && (
            <Table
              headerStyle={{ position: 'relative' }}
              structure={Structures.InboundCampionarioProductStructure(
                products?.reduce((acc, curr) => acc + curr.quantity, 0) ?? 0
              )}
              loading={loading}
              data={products?.filter((product) => product.upc.toLowerCase().includes(filter.toLowerCase()))}
              emptyStyle={{ height: 300 }}
            />
          )}
        </Page.Content>
      </Page>
    )
  }
}
