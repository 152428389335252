import { Session, SessionMovement } from 'api/types'
import TmrBaseResource from './TmrBaseResource'
import api, { responseErrorCheck } from './api'
import SessionMovements from './SessionMovements'

export default class Sessions extends TmrBaseResource {
  static endpoint = '/sessions'

  static async fullGet(sessionId: string) {
    const session = responseErrorCheck<Session>(await api.get<any>(`${this.endpoint}/${sessionId}`))
    session.packs = []
    const packs = responseErrorCheck<SessionMovement[]>(
      await api.get<any>(`/sessionMovements`, { 'session.id': session.id })
    )
    const promises = packs.map((pack) => async () => {
      await SessionMovements.fillItems(pack)
      session.packs.push(pack)
    })
    await Promise.all(promises.map((p) => p()))
    session.packs.map((pack) => (pack.collapsed = true))
    return session
  }

  static async close(sessionId: string) {
    return responseErrorCheck<any>(await api.post(`/sessions/${sessionId}/close`))
  }
}
