import Encodings from 'api/Encodings'
import { CommessaProductionOrderRow } from 'api/types'
import { Box, Input, Table } from 'components'
import { TableStructure } from 'components/Table'
import React, { Component } from 'react'
import { showToast } from 'shared/utils'
import { T, __ } from 'translations/i18n'

interface Props {
  onCommessaProductionOrderRowPress: (row: CommessaProductionOrderRow) => void
}

interface State {
  commessaProductionOrderRows: CommessaProductionOrderRow[]
  loading: boolean
}

export default class CommessaProductionOrderRowModal extends Component<Props, State> {
  state: State = {
    loading: true,
    commessaProductionOrderRows: [],
  }

  structure: TableStructure[] = [
    {
      customValue: (row: CommessaProductionOrderRow) => `${row.order.code}`,
      label: __(T.misc.production_order),
      flex: 1,
      style: { wordBreak: 'break-all' },
    },
    {
      customValue: (row: CommessaProductionOrderRow) => `${row.product.code}`,
      label: 'EAN13',
      width: 250,
      style: { wordBreak: 'break-all' },
    },
    {
      label: __(T.misc.quantity_no_count),
      customValue: (row: CommessaProductionOrderRow) => `${row.encoded ?? 0} / ${row.quantity}`,
      width: 200,
      style: { wordBreak: 'break-all' },
    },
  ]

  getCommessaProductionOrderRows = async (params?: any) => {
    this.setState({ loading: true })
    try {
      const commessaProductionOrderRows = await Encodings.getOpenCommessaPorductionOrderRows(params)
      this.setState({ commessaProductionOrderRows, loading: false })
    } catch (error) {
      showToast({ status: 'error', description: error.message })
      this.setState({ loading: false })
    }
  }

  async componentDidMount() {
    this.getCommessaProductionOrderRows()
  }

  render() {
    const { commessaProductionOrderRows, loading } = this.state
    return (
      <Box>
        <Box row style={{ alignItems: 'end', marginBottom: 20 }} flex>
          <Box flex />
          <Input
            label="UPC"
            debounce={500}
            onChange={(code) => {
              this.getCommessaProductionOrderRows({ 'product.code': code })
            }}
          />
        </Box>
        <Table
          headerStyle={{ position: 'relative' }}
          style={{ paddingTop: 0 }}
          structure={this.structure}
          loading={loading}
          data={commessaProductionOrderRows ?? []}
          emptyStyle={{ height: 300 }}
          onRowPress={(item) => {
            console.log(item)
            this.props.onCommessaProductionOrderRowPress(item)
          }}
        />
      </Box>
    )
  }
}
