import React, { Component } from 'react'
import { Page, Tab, Placeholder, PlaceholderError, Box, Button } from 'components'
import { getLocationState, navigate, navigateBack, getMatchParams } from 'shared/router'
import { askUserConfirmation, showToast, sleep } from 'shared/utils'
import Encodings from 'api/Encodings'
import { ProductionOrder, ProductionOrderRow, TmrTag, ReadIdentifierType, CommessaProductionOrderRow } from 'api/types'
import RfidReader from 'shared/RfidReader'
import AppStore from 'AppStore'
import { T, __ } from 'translations/i18n'
import Sounds from 'shared/Sounds'
import RemoteConfig, { EncodingConfig } from 'shared/RemoteConfig'
import EncodingProvider, { EncodingType } from 'pages/Encoding/EncodingProvider'
import ProductionOrderInputBox from 'pages/Encoding/ProductionOrderInputBox'
import ProductionOrderRowInputBox from 'pages/Encoding/ProductionOrderRowInputBox'
import CertilogoInputBox from 'pages/Encoding/CertilogoInputBox'
import IdentifiersGrid from 'pages/Encoding/IdentifiersGrid'
import CommessaProductionOrderRowModal from './CommessaProductionOrderRowModal'

export type EncodingPageParams = {
  navigateBack?: boolean
  data?: any
  encodeFn?: EncodingType
  hideOptions?: boolean
  title?: string
  autoBackOnEncoded?: boolean
}

interface State {
  productionOrderValue?: string
  errorProductionOrder?: string
  identifiers: ReadIdentifierType[]
  errorProductionOrderRow?: any
  productionOrder?: ProductionOrder
  productionOrderRow?: ProductionOrderRow
  validateResponse?: any
  associateResponse?: any
  options: any
  readerError: boolean
  associating: boolean
  canReset: boolean
  locationState?: EncodingPageParams
}
// eslint-disable-next-line @typescript-eslint/ban-types
export default class Encoding extends Component<{}, State> {
  state: State = {
    identifiers: [],
    options: [
      { value: 'associate', label: __(T.misc.associate), active: true },
      { value: 'verify', label: __(T.misc.verify), active: false },
    ],
    readerError: false,
    associating: false,
    canReset: false,
    locationState: getLocationState(this.props),
  }

  operation = RemoteConfig.getOperationConfig<EncodingConfig>(getMatchParams(this.props).configCode)

  encodingInitialType = this.operation.initialType

  timer!: NodeJS.Timeout

  ignoreEpcs: string[] = []

  inputProductionOrder = React.createRef<HTMLInputElement>()

  inputEAN = React.createRef<HTMLInputElement>()

  async componentDidMount() {
    if ((!AppStore.workstations || AppStore.workstations.length === 0) && !AppStore.emulation) {
      navigate('/')
      showToast({
        sound: false,
        title: __(T.error.error),
        description: __(T.messages.no_workstation_selected),
        status: 'error',
      })
      return
    }

    await RfidReader.initialize()
    this.stopReader()
    switch (this.encodingInitialType) {
      case 'PRODUCT':
        this.inputEAN?.current?.focus()
        break
      case 'IDENTIFIER':
        this.inputProductionOrder?.current?.focus()
        break
      case 'PRODUCTIONORDERROW':
      case 'ORDER':
      default:
        this.inputProductionOrder?.current?.focus()
        break
    }
  }

  componentWillUnmount() {
    RfidReader.stop()
    RfidReader.clear()
  }

  stopReader = async () => {
    RfidReader.clear()
    await RfidReader.stop()
  }

  resetState = () =>
    this.setState({
      errorProductionOrder: undefined,
      errorProductionOrderRow: undefined,
      productionOrderRow: undefined,
      associateResponse: undefined,
      validateResponse: undefined,
      productionOrderValue: '',
      productionOrder: undefined,
      identifiers: [],
    })

  preClearProduct = () => {
    if (this.encodingInitialType === 'PRODUCTIONORDERROW') {
      this.clearProductionOrder()
      return
    }
    this.clearProduct()
  }

  clearProduct = () => {
    this.ignoreEpcs = []
    this.stopReader()
    if (this.encodingInitialType === 'IDENTIFIER') {
      this.resetState()
      return
    }
    this.setState({
      errorProductionOrder: undefined,
      errorProductionOrderRow: undefined,
      productionOrderValue: '',
      productionOrderRow: undefined,
      associateResponse: undefined,
      validateResponse: undefined,
      identifiers: [],
    })
  }

  clearProductionOrder = () => {
    this.stopReader()
    this.clearProduct()
    this.setState(
      {
        errorProductionOrderRow: undefined,
        errorProductionOrder: undefined,
        productionOrderValue: '',
        productionOrder: undefined,
        productionOrderRow: undefined,
      },
      () => {
        this.inputProductionOrder?.current?.focus()
      }
    )
  }

  resetReading = () => {
    const { productionOrderRow } = this.state
    const idfs: any = []
    productionOrderRow?.product.itemConfiguration.identifiers
      .sort((a, b) => {
        if (a.type < b.type) {
          return 1
        }
        if (b.type > a.type) {
          return -1
        }
        return 0
      })
      .sort((a) => (a.type === 'CertilogoIdentifier' ? -1 : 1))
      .forEach((idf) => {
        for (let index = 0; index < idf.amount; index++) {
          idfs.push({ status: 'waiting', type: idf.type })
        }
      })
    this.setState({
      identifiers: idfs,
      validateResponse: undefined,
      associateResponse: undefined,
      associating: false,
      canReset: false,
    })
    RfidReader.clear()
    if (!RfidReader.isReading()) RfidReader.start(this.onTagReadCallback)
  }

  verify = async (idfs: any[]) => {
    try {
      const { productionOrder, productionOrderRow } = this.state
      if (idfs.length === 0 || !productionOrderRow) return

      //Add certilogo identifier code if exists
      // const certilogoIndex = idfs.findIndex((idf) => idf.type === 'CertilogoIdentifier')
      // if (certilogoIndex >= 0) {
      //   idfs[certilogoIndex] = { code: productionOrderRowValue, status: 'reading', type: 'CertilogoIdentifier' }
      // }

      const res = await Encodings.validate(idfs, {
        configurationId: this.operation.id,
        productionOrderId: productionOrder?.id,
        productId: productionOrderRow?.product.id,
        identifiers: idfs.filter((idf) => !!idf.code) as any,
      })

      this.setState({ identifiers: idfs, validateResponse: res })

      if (!res.success) {
        clearTimeout(this.timer)
        this.setState({ associateResponse: undefined })
        return
      }

      this.associate(idfs, productionOrder, productionOrderRow)
    } catch (err) {
      showToast({
        title: __(T.error.error),
        description: err?.message ?? 'Generic error',
        status: 'error',
      })
    }
  }

  associate = (idfs, productionOrder, productionOrderRow) => {
    this.setState({ associating: true })

    //Start timer to delay the association (in case of multiple reading)
    this.timer = setTimeout(async () => {
      try {
        await RfidReader.stop()
        if (this.operation.enableWrite) {
          await EncodingProvider.writeUHFTags(this.operation, idfs, productionOrderRow, (nextEpc) => {
            this.ignoreEpcs.push(nextEpc)
          })
        }
        const associateRes = await EncodingProvider.encode(
          {
            configurationId: this.operation.id,
            productionOrderId: productionOrder?.id,
            productId: productionOrderRow?.product?.id,
            identifiers: idfs.filter((idf) => idf.code) as any,
          },
          this.state.locationState?.encodeFn,
          this.state.locationState?.data
        )
        // const associateRes = await Encodings.associate({
        //   productionOrderId: productionOrder?.id,
        //   productId: productionOrderRow?.product?.id,
        //   identifiers: idfs.filter((idf) => idf.code) as any,
        // })
        this.ignoreEpcs = idfs.map((idf) => idf.code ?? '')
        this.setState({ associateResponse: associateRes, associating: false })

        await sleep(500)
        if (associateRes?.success) {
          Sounds.success()
          await AppStore.increaseDailyItems()
        } else {
          Sounds.fail()
          throw new Error(
            associateRes?.errors?.[0].errorCode ??
              `Generic error, probably because encodeFn ${this.state.locationState?.encodeFn} is not implemented in EncodingProvider.encode`
          )
        }
        if (this.state.locationState?.autoBackOnEncoded) {
          navigateBack()
        }
        const EANCode = productionOrderRow?.product?.code || ''
        if (this.encodingInitialType === 'IDENTIFIER') {
          this.clearProduct()
          return
        }
        if (this.encodingInitialType === 'PRODUCTIONORDERROW') {
          this.searchProductionOrder(productionOrderRow.rowCode)
          return
        }
        this.searchProduct(EANCode)
      } catch (error) {
        if (error && error.message) {
          showToast({
            title: __(T.error.error),
            description: error?.message ?? error,
            status: 'error',
          })
        }
        this.setState({ associateResponse: { success: false }, associating: false, canReset: true })
      }
    }, 1000)
  }

  onTagReadCallback = async (tag: TmrTag) => {
    try {
      const { identifiers } = this.state
      const idfs = [...identifiers]

      EncodingProvider.onTagRead(tag, idfs, this.ignoreEpcs)

      this.setState({ identifiers: idfs })

      this.verify(idfs)
    } catch (err) {
      //excluded epc
    }
  }

  searchProduct = async (productCode: string) => {
    const { productionOrderValue, productionOrder } = this.state

    try {
      this.setState({ readerError: false, errorProductionOrder: undefined })

      const { idfs, data } = await Encodings.getProductionOrderRows(this.operation, this.encodingInitialType!, {
        orderCode: productionOrderValue,
        identifier: productCode,
        productCode,
        productionOrder,
      })

      if (this.encodingInitialType === 'IDENTIFIER' && data.encoded >= data.quantity) {
        throw new Error('ENCODING_ERROR.IDENTIFIER_ALREADY_ENCODED')
      }

      this.setState({ errorProductionOrderRow: undefined })
      Sounds.tap()
      await RfidReader.initialize()
      await RfidReader.stop()
      const newState = {
        identifiers: idfs,
        productionOrderRow: data,
        productionOrder: data.order,
        readerError: false,
        associateResponse: undefined,
        validateResponse: undefined,
      }
      await this.startAntenna(newState)

      return
    } catch (err) {
      Sounds.error()
      this.setState({
        errorProductionOrderRow: EncodingProvider.getEncodingMessageError(err, productCode, this.encodingInitialType),
      })
    }
  }

  startAntenna = async (newState: any) => {
    const antennaStarted = await RfidReader.start(this.onTagReadCallback)
    if (antennaStarted) {
      this.setState(newState)
    } else if (AppStore.emulation) {
      this.setState(newState)
      showToast({
        title: __(T.misc.info),
        description: 'Emulation enabled, cannot connect to workstations',
        status: 'info',
      })
    }
  }

  searchProductionOrder = async (value: string) => {
    if (this.encodingInitialType === 'PRODUCTIONORDERROW') {
      try {
        const { idfs, data } = await Encodings.getProductionOrderRows(this.operation, this.encodingInitialType!, {
          productionOrderRowCode: value,
        })
        const newState = {
          identifiers: idfs,
          productionOrderRow: data as any,
          productionOrder: data.order,
          readerError: false,
          associateResponse: undefined,
          validateResponse: undefined,
        }
        await this.startAntenna(newState)
        return
      } catch (error) {
        showToast({ status: 'error', description: 'Error while searching production order' })
        return
      }
    }
    try {
      const data = await Encodings.getInfoProductionOrder(this.operation, value)

      if (!data) throw new Error()
      Sounds.tap()
      this.setState({
        errorProductionOrder: undefined,
        productionOrderValue: value,
        productionOrder: data,
      })
      await sleep(100)
      this.inputEAN?.current?.focus()
    } catch (error) {
      this.setState({ errorProductionOrder: __(T.error.production_order_not_found, { code: value }) })
    }
  }

  onResetDailyItems = async () => {
    if (await askUserConfirmation(__(T.titles.reset_daily_items), __(T.messages.are_you_sure_to_reset_daily_items))) {
      AppStore.resetDailyItems()
      this.forceUpdate()
    }
  }

  showCommessaModal = async () => {
    AppStore.openModal({
      id: 'CommessaProductionOrderRowModal',
      visible: true,
      size: '4xl',
      body: (
        <CommessaProductionOrderRowModal
          onCommessaProductionOrderRowPress={(row: CommessaProductionOrderRow) => {
            AppStore.closeModal('CommessaProductionOrderRowModal')
            this.searchProductionOrder(row.rowCode)
          }}
        />
      ),
    })
  }

  render() {
    const {
      productionOrder,
      errorProductionOrder,
      productionOrderRow,
      errorProductionOrderRow,
      productionOrderValue,
      options,
      identifiers,
      validateResponse,
      associateResponse,
      readerError,
      associating,
      canReset,
      locationState,
    } = this.state

    const title = this.operation.description ?? locationState?.title ?? 'Encoding'

    const headerRight = !locationState?.hideOptions ? (
      <Tab options={options} onOptionSelected={(opt) => EncodingProvider.getOptionEncodingPage(opt, this.operation)} />
    ) : undefined

    return (
      <Page
        title={title}
        header={{
          details: [
            {
              label: __(T.misc.items),
              value: `${AppStore.dailyItems}`,
              onPress: AppStore.dailyItems > 0 ? this.onResetDailyItems : undefined,
            },
          ],
        }}
        headerRight={headerRight}
        enableEmulation
        onBackPress={locationState?.navigateBack ? navigateBack : undefined}
      >
        <Page.Sidebar>
          {this.encodingInitialType === 'ORDER' && (
            <ProductionOrderInputBox
              productionOrder={productionOrder}
              productionOrderValue={productionOrderValue}
              errorProductionOrder={errorProductionOrder}
              inputProductionOrder={this.inputProductionOrder}
              clearProductionOrder={this.clearProductionOrder}
              searchProductionOrder={this.searchProductionOrder}
            />
          )}
          {this.encodingInitialType === 'PRODUCTIONORDERROW' && (
            <>
              <ProductionOrderInputBox
                autoFocus={false}
                productionOrder={productionOrder}
                productionOrderValue={productionOrderValue}
                errorProductionOrder={errorProductionOrder}
                inputProductionOrder={this.inputProductionOrder}
                clearProductionOrder={this.clearProductionOrder}
                searchProductionOrder={this.searchProductionOrder}
              />
              {!productionOrder && (
                <Box row vcenter style={{ justifyContent: 'space-between', marginTop: 30 }}>
                  <p style={{ fontWeight: 'bold', fontSize: 20, marginRight: 10 }}>{__(T.misc.or)}</p>
                  <Button title={__(T.misc.select_order_row)} onClick={this.showCommessaModal} />
                </Box>
              )}
            </>
          )}
          {((productionOrder &&
            (this.encodingInitialType === 'ORDER' || this.encodingInitialType === 'PRODUCTIONORDERROW')) ||
            this.encodingInitialType === 'PRODUCT') && (
            <ProductionOrderRowInputBox
              productionOrderRow={productionOrderRow}
              errorProductionOrderRow={errorProductionOrderRow}
              inputEAN={this.inputEAN}
              searchProduct={this.searchProduct}
              clearProduct={this.preClearProduct}
              searchProductionOrderSetting={
                this.encodingInitialType === 'ORDER' || this.encodingInitialType === 'PRODUCTIONORDERROW'
              }
            />
          )}

          {this.encodingInitialType === 'IDENTIFIER' && (
            <CertilogoInputBox
              productionOrderRow={productionOrderRow}
              errorProductionOrderRow={errorProductionOrderRow}
              inputEAN={this.inputEAN}
              searchProduct={this.searchProduct}
              clearProduct={this.preClearProduct}
              searchProductionOrderSetting={this.encodingInitialType === 'IDENTIFIER'}
            />
          )}
        </Page.Sidebar>
        <Page.Content>
          {readerError && !AppStore.emulation && (
            <Box center>
              <PlaceholderError>{__(T.misc.unable_to_connect_to_workstation)}</PlaceholderError>
            </Box>
          )}
          {productionOrderRow && (
            <IdentifiersGrid
              productionOrderRow={productionOrderRow}
              identifiers={
                identifiers.length > 0
                  ? [
                      ...identifiers.filter(
                        (i) => i.type === 'UHFTag' || i.type === 'NFCTag' || i.type === 'CertilogoIdentifier'
                      ),
                      //Add associating operation box
                      EncodingProvider.getAssociationStatus(
                        associateResponse,
                        associating,
                        validateResponse,
                        this.operation.enableWrite
                      ),
                    ]
                  : []
              }
              onTagReadCallback={this.onTagReadCallback}
              removeErrorTag={EncodingProvider.checkErrorTag(identifiers) || canReset ? this.resetReading : undefined}
              placeholder={
                identifiers.length === 0 && (
                  <Placeholder style={{ width: 370 }}>{__(T.misc.enter_the_necessary_fields)}</Placeholder>
                )
              }
            />
          )}
        </Page.Content>
      </Page>
    )
  }
}
