import AppStore from 'AppStore'
import config from 'config/config'
import api, { responseErrorCheck } from './api'
import { Product } from './types'
import TmrBaseResource from './TmrBaseResource'

export default class Products extends TmrBaseResource {
  static endpoint = 'products'

  static get(code: string): any {
    return api
      .get<any[]>(`products?code=${code}`)
      .then(responseErrorCheck)
      .then((res) => res)
  }

  static async getProductsByCodes(upcs: string[]): Promise<Product[]> {
    return api
      .post<Product | any>(`${this.endpoint}/advancedSearch`, {
        codes: upcs,
        limit: 50000,
      })
      .then(responseErrorCheck)
  }

  static getImageUrl(code?: string): string {
    if (!code) {
      return ''
    }
    const backendBaseUrl = api.getBaseURL().replace(/\/$/, "")
    const imageUrl = `${backendBaseUrl}/custom/${Products.endpoint}/image/${code}/null/big/null/default.jpg?x-tmr-token=${AppStore.authToken}`
    return imageUrl
  }
}
