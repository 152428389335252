import Sessions from 'api/Sessions'
import { navigate } from 'shared/router'
import SessionMovements from 'api/SessionMovements'

export default class ShipmentProvider {
  static async retrieveDDT(shippingId: string) {
    let ddt = await Sessions.fullGet(shippingId)
    if (!ddt) {
      navigate('/inbound-campionario')
      return undefined
    }
    let edited = false
    await Promise.all(
      ddt.packs.map(async (pack) => {
        if (pack.items?.length === 0) {
          await SessionMovements.delete(pack.id)
          edited = true
        }
      })
    )
    if (edited) ddt = await Sessions.fullGet(shippingId)
    return ddt
  }
}
