import TmrBaseResource from './TmrBaseResource'
import api, { responseErrorCheck } from './api'

export default class Places extends TmrBaseResource {
  static endpoint = '/places'

  static searchPlaceById<TmrPlace>(params = {}, requestConfig = undefined): Promise<TmrPlace[]> {
    return api.get<TmrPlace[]>(`${this.endpoint}`, params, requestConfig).then(responseErrorCheck)
  }
}
