import Items from 'api/Items'
import SessionItems from 'api/SessionItems'
import SessionMovements from 'api/SessionMovements'
import Sessions from 'api/Sessions'
import { Session, SessionMovement, TmrItem } from 'api/types'
import AppStore from 'AppStore'
import { AntennaButton, Box, Button, EmulateButton, ItemRow, Page, Spacer, TagCounter, TextBox } from 'components'
import React, { Component } from 'react'
import RfidReader from 'shared/RfidReader'
import { getMatchParams, navigate } from 'shared/router'
import testTags from 'shared/testTags'
import { showToast } from 'shared/utils'
import { T, __ } from 'translations/i18n'

interface MatchParams {
  shippingId: string
  parcelId: string
}

interface State {
  ddt?: Session
  parcel?: SessionMovement
  loading: boolean
  error?: string
}

export default class InboundCampionarioParcelRead extends Component<{}, State> {
  unexpectedFound = false

  params: MatchParams = {
    ...getMatchParams(this.props),
  }

  state: State = {
    loading: true,
  }

  async componentDidMount() {
    try {
      const ddt = await Sessions.fullGet(this.params.shippingId)
      if (!ddt) {
        navigate('/inbound-campionario')
        throw new Error(`Impossibile trovare il ddt`)
      }

      const parcel = await SessionMovements.get<SessionMovement>(this.params.parcelId)
      if (!parcel) {
        navigate('/inbound-campionario/:shippingId', { shippingId: ddt.id })
        throw new Error(`Impossibile trovare il collo`)
      }

      SessionMovements.fillItems(parcel)
      RfidReader.setDecodeFunction(Items.customDecode)
      RfidReader.onDecodedItemCallback = this.onDecodedItem
      this.setState({ ddt, parcel, loading: false })
    } catch (error) {
      showToast({
        title: __(T.error.error),
        description: error?.message ?? 'Generic error',
        status: 'error',
      })
    }
  }

  onDecodedItem = (itemMap: { [epc: string]: TmrItem | undefined } | { epc: string }) => {
    const item = Object.values(itemMap)[0] ?? {}
    const { parcel, ddt } = this.state
    let { error } = this.state

    item.epc = Object.keys(itemMap)[0]

    if (!parcel) {
      showToast({
        title: __(T.error.error),
        description: '' ?? 'Generic error',
        status: 'error',
      })
      return
    }

    if (!('upc' in item) && !('product' in item) && !('id' in item) && 'epc' in item) {
      //NON DECODIFICABILE PRODOTTO NON A SISTEMA ITEM NON A SISTEMA
      ;(item as any).upc = parcel.code
      parcel.items?.push(item as any)
      this.setState({ parcel, error })
      return
    }

    if ('upc' in item && !item.product && item.upc === '999999999999') {
      //DECODIFICABILE IN 999999999999
      item.upc = parcel.code
      parcel.items?.push(item)
      this.setState({ parcel, error })
      return
    }

    if (item.upc && !item?.id) {
      //DECODIFICABILE PRODOTTO NON A SISTEMA ITEM NON A SISTEMA
      return
    }

    if (item.upc !== parcel.code && item.product?.code !== parcel.code) {
      //ITEM CON PRODOTTO DIVERSO RISPETTO AL COLLO
      error = 'Trovato item con prodotto diverso dal collo'
      ;(item as any).status = __(T.misc.invalid_tag)
    }

    if (
      item.zone &&
      AppStore.zones?.filter((zone) => zone.zoneType === 'STOCK' && zone.id === item.zone?.id).length === 1
    ) {
      //ITEM IN STOCK
      return
    }

    if (ddt?.packs.filter((p) => !!p.items?.find((i) => i.epc === item.epc)).length !== 0) {
      //ITEM GIA LETTO
      return
    }

    parcel.items?.push(item as any)
    this.setState({ parcel, error })
  }

  navigateBack = () => navigate('/inbound-campionario/:shippingId', { shippingId: this.params.shippingId })

  confirmParcel = async () => {
    const { parcel } = this.state
    const sessionItems = parcel?.items?.map((item) => {
      item.sessionMovement = { id: parcel?.id }
      return item
    })
    const bulkCreateResponse = await SessionItems.bulkCreate(sessionItems)
    if (bulkCreateResponse) {
      navigate('/inbound-campionario/:shippingId', { shippingId: this.params.shippingId })
    } else {
      showToast({ title: __(T.error.error), description: 'Impossibile salvare il pacco', status: 'error' })
    }
  }

  clear = () => {
    const { parcel } = this.state
    RfidReader.clear()
    parcel!.items = []
    this.setState({ parcel, error: undefined })
  }

  render() {
    const { ddt, parcel, loading, error } = this.state
    const shippingCode = ddt?.code
    const parcelCode = parcel?.code

    const details = [
      { label: __(T.misc.shipment), value: shippingCode },
      { label: __(T.misc.parcel), value: parcelCode },
    ]

    return (
      <Page
        title="Inbound Campionario"
        onBackPress={this.navigateBack}
        loading={loading}
        header={{
          details,
        }}
        enableEmulation
      >
        <Page.Sidebar style={{ overflow: 'auto' }}>
          <Box flex style={{ overflow: 'auto' }}>
            <TagCounter detected={parcel?.items?.length ?? 0} unexpected={error ? 1 : 0} />
            <AntennaButton
              style={{ backgroundColor: 'white' }}
              onClear={this.clear}
              hideClear={parcel?.items?.length === 0}
            />
            <Spacer />
            <TextBox text={error} type="error" />
          </Box>
          <Button title={__(T.misc.confirm_parcel)} disabled={!!error} onClick={this.confirmParcel} />
        </Page.Sidebar>

        <Page.Content>
          {parcel?.items?.map((item, index) => (
            <ItemRow hideStatus={item.upc === parcel.code} id={index} item={item as any} />
          ))}
          <Box>
            <EmulateButton title="Emulate tags 1" onPress={() => RfidReader.emulateTags(testTags.parcel1)} />
            <EmulateButton title="Emulate tags 2" onPress={() => RfidReader.emulateTags(testTags.parcel2)} />
          </Box>
        </Page.Content>
      </Page>
    )
  }
}
