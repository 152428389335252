import React, { Component } from 'react'
import styled from '@emotion/styled'
import { Image, Box, RoundedLabel, Icons, ItemInfoModal, ItemStatusLabel } from 'components'
import { TmrItem } from 'api/types'
import Products from 'api/Products'
import { T, __ } from 'translations/i18n'
import { Spacer } from './Various'

interface Props {
  id: any
  item: TmrItem
  onInfoPress?: (item: TmrItem) => void
  onDeleteCallback?: (item: TmrItem) => void
  hideStatus: boolean
  itemState?: 'ERROR' | 'WARNING' | 'IGNORE'
}

interface State {
  visibleItemModal: boolean
}

export default class ItemRow extends Component<Props, State> {
  static defaultProps: Partial<Props> = {
    hideStatus: false,
  }

  state: State = {
    visibleItemModal: false,
  }

  openItemInfoModal = () => this.setState({ visibleItemModal: true })
  closeItemInfoModal = () => this.setState({ visibleItemModal: false })

  onRowPress = () => {
    const { onInfoPress, item } = this.props
    if (onInfoPress) return () => onInfoPress(item)
    if (!item.id) return undefined
    if (!onInfoPress) return this.openItemInfoModal
    return undefined
  }

  getItemMessages = () => {
    const { item, itemState } = this.props
    const messages: { message: string; status: 'error' | 'warning' }[] = []
    item.__processedStates
      ?.filter((state) => (itemState ? state.processedState === itemState : true))
      .forEach((processedState) => {
        if (processedState.itemState === 'ITEM_NOT_IN_INBOUND')
          messages.push({
            message: __(T.error.item_not_in_inbound),
            status: processedState.processedState.toLowerCase() as 'error' | 'warning',
          })
        if (processedState.itemState === 'ITEM_NOT_IN_STOCK')
          messages.push({
            message: __(T.error.item_not_in_stock),
            status: processedState.processedState.toLowerCase() as 'error' | 'warning',
          })
        if (processedState.itemState === 'ITEM_IN_ANOTHER_PARCEL')
          messages.push({
            message: __(T.error.item_in_another_parcel),
            status: processedState.processedState.toLowerCase() as 'error' | 'warning',
          })
        if (processedState.itemState === 'ITEM_IN_ANOTHER_PRODUCTION_ORDER')
          messages.push({
            message: __(T.error.item_in_another_production_order),
            status: processedState.processedState.toLowerCase() as 'error' | 'warning',
          })
      })

    if (messages.length === 0) return undefined

    return messages
  }

  render() {
    const { item, id, onDeleteCallback } = this.props
    const { visibleItemModal } = this.state
    if (!item) return <></>
    const { product } = item

    let tagType
    if (!item.product) tagType = __(T.misc.unknown_product)
    if (!item.product && item.upc) tagType = __(T.misc.unknown_tag)
    if (!item.upc && !item.product) tagType = __(T.misc.invalid_tag)
    if (item.status === __(T.misc.invalid_tag)) tagType = __(T.misc.invalid_tag)

    const upc = item.upc ?? item.product?.code

    const messages = this.getItemMessages()

    return (
      <Box mb={20} row>
        <Row flex row id={id} onClick={this.onRowPress()}>
          <Box center p={10}>
            <ProductImage src={Products.getImageUrl(product?.code)} alt="item" />
          </Box>

          <Separator />

          <Box flex p={20} pr={0}>
            <Box style={{ maxWidth: 'fit-content', wordBreak: 'break-word', whiteSpace: 'pre-wrap' }}>
              <ProductCode>{upc ?? __(T.misc.unknown).toUpperCase()}</ProductCode>
              <ItemEpc>{item.product?.description}</ItemEpc>
              {/* {item.itemIdentifiers.map((idf) => (
              <ItemEpc>
                {idf.identifierType}: {idf.code}
              </ItemEpc>
            ))} */}
            </Box>
            <Spacer />
            <Box row style={{ justifyContent: 'flex-start', flexWrap: 'wrap' }}>
              <RoundedLabel mb={4} maxWidth={230} label={__(T.misc.style)} value={product?.style?.value} />
              <RoundedLabel mb={4} maxWidth={130} label={__(T.misc.material)} value={product?.material?.value} />
              <RoundedLabel mb={4} maxWidth={150} label={__(T.misc.color)} value={product?.color?.value} />
              <RoundedLabel mb={4} maxWidth={130} label={__(T.misc.size)} value={product?.size?.value} />
              <RoundedLabel mb={4} value={tagType?.toUpperCase()} />
            </Box>
            {!!messages && (
              <Box row style={{ justifyContent: 'flex-start', marginTop: 5 }} flex>
                {messages &&
                  messages.map((message) => <ItemStatusLabel status={message.status} value={message.message} />)}
              </Box>
            )}
          </Box>
          {this.onRowPress() && (
            <Box p={30} center>
              <Icons.Info />
            </Box>
          )}
          {visibleItemModal && (
            <ItemInfoModal visible={visibleItemModal} item={item} onClose={this.closeItemInfoModal} />
          )}
        </Row>
        {!!onDeleteCallback && (
          <TrashButton p={15} center onClick={() => onDeleteCallback(item)}>
            <Icons.Trash fill="white" />
          </TrashButton>
        )}
      </Box>
    )
  }
}

const Row = styled(Box)`
  border: 2px solid #edecec;
  box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.08);
  border-radius: 10px;
`

const Separator = styled.div`
  width: 2px;
  height: 100%;
  background-color: #edecec;
`

const ProductImage = styled(Image)`
  width: 100px;
  height: 100px;
  padding: 10px;
`

const ProductCode = styled.div`
  font-weight: 900;
  font-size: 28px;
`

const ItemEpc = styled.div`
  font-weight: 400;
  font-size: 20px;
`

const TrashButton = styled(Box)`
  background: #eb5757;
  border-radius: 100%;
  height: 60px;
  width: 60px;
  align-self: center;
  margin-left: 15px;
`
