import React, { Component } from 'react'
import { SessionMovement } from 'api/types'
import { Box, Spacer } from 'components'

interface Props {
  parcel: SessionMovement
}
export default class CustomParcelSectionHeader extends Component<Props> {
  render() {
    const { parcel } = this.props
    return (
      <Box vcenter row flex pr={20}>
        {parcel.code ?? 'No parcel code provided'}
        <Spacer />
        <Box flex />
      </Box>
    )
  }
}
