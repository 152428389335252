import React, { Component } from 'react'
import { Form, Page, Box, Icons } from 'components'
import { navigate } from 'shared/router'
import { T, __ } from 'translations/i18n'
import Sessions from 'api/Sessions'
import { showToast } from 'shared/utils'
import { Session } from 'api/types'
import Zones from 'api/Zones'
import AppStore from 'AppStore'

interface State {
  initialValues?: any
}
export default class InboundCampionarioCreate extends Component<{}, State> {
  state: State = {
    initialValues: {},
  }

  submit = async (data: { shipmentCode: string }) => {
    const { shipmentCode } = data
    const sessions = await Sessions.search<Session>({ code: shipmentCode })
    if (sessions && sessions.length === 1) {
      if (sessions[0].closingDate) {
        showToast({ title: __(T.error.error), status: 'info', description: 'DDT già confermato' })
        return
      }
      navigate(`/inbound-campionario/:shippingId`, { shippingId: sessions[0].id })
    } else {
      const stockZone = (await Zones.search({ 'place.id': AppStore.loggedUser?.place.id, zoneType: 'STOCK' }))[0]
      const session = await Sessions.save({ code: shipmentCode, destinationZone: stockZone })
      navigate(`/inbound-campionario/:shippingId`, { shippingId: session.id })
    }
  }

  render() {
    const { initialValues } = this.state

    return (
      <Page title="Inbound Campionario" onBackPress={() => navigate(`/`)}>
        <Page.Content bgGrey>
          <Box flex center>
            <Form
              schema={[
                {
                  label: 'Inserisci codice DDT',
                  name: 'shipmentCode',
                  image: <Icons.Barcode />,
                  required: true,
                  focus: true,
                },
              ]}
              initialValues={initialValues}
              submitText={__(T.misc.next)}
              onSubmit={this.submit}
              width={400}
            />
          </Box>
        </Page.Content>
      </Page>
    )
  }
}
