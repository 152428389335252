import { ShipmentParcel, ShipmentParcelPreview, TmrZone } from 'api/types'
import React from 'react'
import { Box, CustomEmailNotesModal, Modal, Spacer, Form } from 'components'
import { InboundConfig } from 'shared/RemoteConfig'
import { closeModal, openModal, showToast } from 'shared/utils'
import Zones from 'api/Zones'
import { T, __ } from 'translations/i18n'

export async function inboundParcelBeforeConfirm(parcel: ShipmentParcel[], operation: InboundConfig, parcelData: any) {
  return new Promise((resolve, reject) => {
    if (operation.code === 'inbound-parcelbyparcel') {
      openModal({
        id: 'emailAndNotesModal',
        modal: (
          <CustomEmailNotesModal
            onConfirm={(emails: string[], notes?: string) => {
              parcelData.attributes = { emails: emails.join(','), notes }
              resolve(true)
            }}
            onClose={() => {
              closeModal('emailAndNotesModal')
              reject()
            }}
          />
        ),
      })
    } else resolve(true)
  })
}

export async function inboundParcelAfterConfirm(parcel: ShipmentParcel, operation: InboundConfig) {
  return new Promise((resolve, reject) => {
    resolve(true)
  })
}

export async function inboundShipmentBeforeConfirm(
  shipment: ShipmentParcel,
  confirmPayload: any,
  operation: InboundConfig
) {
  return new Promise((resolve, reject) => {
    resolve(true)
  })
}

export async function inboundShipmentAfterConfirm(
  parcel: ShipmentParcel,
  confirmResponse: ShipmentParcelPreview,
  operation: InboundConfig
) {
  return new Promise((resolve, reject) => {
    resolve(true)
  })
}

const fetchZones = async (placeId: string) => {
  try {
    const zones = (await Zones.search<TmrZone>({ limit: 500, 'place.id': placeId, zoneType: 'STOCK' })) ?? []
    return zones
  } catch (err) {
    //
  }
  return []
}

// eslint-disable-next-line consistent-return
async function customRiceviPezziGetZone(resolve, reject, placeId: string, parcelData: any) {
  const zones = await fetchZones(placeId)
  if (zones.length === 0) return reject('No zone found')

  if (zones.length === 1) {
    parcelData.inboundZone = zones[0].id
    return resolve(true)
  }
  if (zones.length >= 1) {
    return openModal({
      id: 'selectZoneModal',
      body: (
        <Box flex row>
          <Box flex ph={20} pv={20}>
            <Modal.Title style={{ flex: 0 }}>{__(T.misc.select_zone)}</Modal.Title>
            <Spacer />
            <Form
              schema={[
                {
                  placeholder: __(T.misc.destination),
                  name: 'destination',
                  type: 'select',
                  options: zones,
                  config: { value: 'id', label: 'description', secondaryLabel: 'code' },
                },
              ]}
              submitText={__(T.misc.next)}
              onSubmit={(formData) => {
                if (!formData.destination) showToast({ title: 'Please select a zone', status: 'error' })
                else {
                  closeModal('selectZoneModal')
                  parcelData.inboundZone = formData.destination.id
                  resolve(true)
                }
              }}
            />
          </Box>
        </Box>
      ),
    })
  }
}

export async function inboundParcelBeforeOpen(
  operation: InboundConfig,
  parcelPreview: ShipmentParcelPreview,
  parcelData: any
) {
  return new Promise((resolve, reject) => {
    if (operation.code === 'inbound-parcelbyparcel') {
      const placeId = parcelPreview.header.destinationPlace.id
      customRiceviPezziGetZone(resolve, reject, placeId, parcelData)
    } else resolve(true)
  })
}
