import React, { Component } from 'react'
import { Box, Form, Icons, Page } from 'components'
import { getLocationState, getMatchParams, navigate } from 'shared/router'
import { ShippingParcelCreateRequest, TmrPlace } from 'api/types'
import { showToast, uuid } from 'shared/utils'
import Places from 'api/Places'
import { __, T } from 'translations/i18n'
import RemoteConfig, { OutboundConfig } from 'shared/RemoteConfig'
import OutboundShipments from 'api/OutboundShipments'
import CustomConfiguration from 'api/CustomConfiguration'
import Checklist from '../../api/Checklist'

interface Props {
  companyPrefix?: string
}

interface State {
  company?: any
  companyPrefix: string
  places?: TmrPlace[]
  initialValues?: any
  fromShipment: boolean
  loading: boolean
  companyValueSelected: boolean
}

export default class OutboundCreate extends Component<Props, State> {
  operation = RemoteConfig.getOperationConfig<OutboundConfig>(getMatchParams(this.props).configCode)
  form!: Form

  static defaultProps: Props = {
    companyPrefix: localStorage.getItem('companyPrefix') ?? undefined,
  }

  // eslint-disable-next-line @typescript-eslint/no-useless-constructor
  constructor(props: Props) {
    super(props)
  }

  state: State = {
    places: [],
    company: [],
    companyPrefix: this.props.companyPrefix ? JSON.parse(this.props.companyPrefix) : undefined,
    companyValueSelected: true,
    fromShipment: getLocationState(this.props)?.fromShipment,
    initialValues: {
      destination: getLocationState(this.props)?.destination,
      shipmentCode: getLocationState(this.props)?.shipmentCode,
    },
    loading: true,
  }

  componentDidMount() {
    this.fetchCompanyPrefix()
  }

  fetchCompanyPrefix = async () => {
    try {
      const { companyPrefix } = this.state
      const companyListPrefix = (await CustomConfiguration.getCompanyConfigurationCode()) ?? []
      const mappedCompany = companyListPrefix.map((c, index) => {
        return { value: index, description: c }
      })
      if (mappedCompany) {
        this.setState({ company: mappedCompany, companyValueSelected: !companyPrefix, loading: false })
      }
    } catch (err) {
      showToast({
        title: __(T.error.error),
        description: err?.message ?? 'Generic error',
        status: 'error',
      })
    }
  }

  fetchPlaces = async () => {
    const { initialValues } = this.state
    try {
      let places: TmrPlace[]
      if (this.operation.hierarchyId) {
        places = (await Places.search<TmrPlace>({ 'hierarchy.id': this.operation.hierarchyId, limit: 500 })) ?? []
      } else {
        places = (await Places.search<TmrPlace>({ limit: 5000 })) ?? []
      }
      if (this.operation.hideDestinationInput === 'yes' || (places.length === 1 && !initialValues.destination)) {
        initialValues.destination = places[0]
      }
      this.setState({ places, initialValues, loading: false })
    } catch (err) {
      showToast({
        title: __(T.error.error),
        description: err?.message ?? 'Generic error',
        status: 'error',
      })
    }
  }

  submit = async (data) => {
    const { fromShipment, companyPrefix } = this.state
    if (!data.destination && !data.company.value && this.operation.hideDestinationInput === 'no') {
      showToast({
        title: __(T.error.error),
        description: __(T.error.destination_field_required),
        status: 'error',
      })
      return
    }

    let state = {
      shipmentCode: data.shipmentCode ?? uuid(),
      parcelCode: `${companyPrefix}-${data.parcelCode}` ?? uuid(),
      destination: data.destination,
      fromShipment: fromShipment,
    }
    try {
      if (this.operation?.id && (!data.shipmentCode || !data.parcelCode || !data.companyPrefix.description)) {
        const request: ShippingParcelCreateRequest = {
          configurationId: this.operation.id,
          destinationPlaceCode: data.destination.code,
        }
        if (data.shipmentCode) request.shippingCode = data.shipmentCode
        if (data.parcelCode)
          request.parcelCode = companyPrefix ? JSON.parse(companyPrefix) : `${'' + '-'}${data.parcelCode}`

        const response = await OutboundShipments.createParcel(request)
        if (!response || !response?.header?.parcelCode) throw new Error('No Parcel created') // Never shown to user
        state = {
          shipmentCode: response?.header?.shippingCode,
          parcelCode: response?.header?.parcelCode,
          destination: response?.header?.destinationPlace,
          fromShipment,
        }
        navigate(
          state.parcelCode ? '/outbound/:configCode/reading/:parcelCode' : '/outbound/:configCode/reading',
          state.parcelCode
            ? {
                configCode: this.operation?.code,
                parcelCode: state.parcelCode,
                destination: state.destination,
              }
            : { configCode: this.operation?.code },
          { state }
        )
      }
    } catch (error) {
      // if catch an error create shipment and parcel codes with uuid and navigate without errors
    } finally {
      const companySelected = companyPrefix
      if (companySelected) localStorage.setItem('companyPrefix', JSON.stringify(companySelected))
      navigate(
        state.parcelCode ? '/outbound/:configCode/reading/:parcelCode' : '/outbound/:configCode/reading',
        state.parcelCode
          ? { configCode: this.operation?.code, parcelCode: state.parcelCode }
          : { configCode: this.operation?.code },
        { state }
      )
    }
  }

  onBackPress = () => {
    if (this.operation.code === 'outbound-in-triangolazione') {
      navigate(`/`)
      return
    }
    if (this.state.fromShipment) {
      navigate(`/outbound/:configCode/:shippingCode/parcels`, {
        configCode: this.operation?.code,
        shippingCode: this.state.initialValues?.shipmentCode,
      })
      return
    }
    if (this.operation.hasChecklist === 'yes' || this.operation.outboundMode === 'shipment') {
      navigate(`/outbound/:configCode`, { configCode: this.operation?.code })
    } else {
      navigate(`/`)
    }
  }

  onBlurParcelCode = async (parcelCode: any) => {
    const { companyPrefix } = this.state
    if (this.operation.code === 'outbound-in-triangolazione') {
      if (!parcelCode || parcelCode === '' || parcelCode.indexOf(' ') >= 0) return
      const checklist: any[] = await Checklist.get(`${companyPrefix}-${parcelCode}`)
      if (!checklist || checklist.length === 0 || !checklist[0].destinationPlace) return
      let place
      try {
        const idPlaces = checklist.map((c) => c.destinationPlace.id)
        place = (await Places.searchPlaceById<TmrPlace>({ id: idPlaces })) ?? null
        this.setState({ places: place, loading: false })
      } catch (err) {
        showToast({
          title: __(T.error.error),
          description: err?.message ?? 'Generic error',
          status: 'error',
        })
      }
      this.forceUpdate()
    }
  }

  resetDestination() {
    const { initialValues } = this.state
    initialValues.destination = null
    this.form.handleChange('destination', null)
    this.setState({ places: [], initialValues })
  }

  render() {
    const { places, initialValues, loading, company, companyValueSelected } = this.state

    return (
      <Page title={this.operation?.description ?? 'Outbound'} onBackPress={this.onBackPress}>
        <Page.Content bgGrey>
          <Box flex center loading={loading}>
            <Form
              ref={(ref) => (this.form = ref!)}
              schema={[
                {
                  label: __(T.misc.shipment_code_auto),
                  name: 'shipmentCode',
                  image: <Icons.Barcode />,
                  focus: !initialValues.shipmentCode,
                  hide: this.state.fromShipment || this.operation.hideShipmentInput === 'yes',
                },
                {
                  placeholder: 'Company',
                  name: 'companyPrefix',
                  type: 'select-withDefaultValue',
                  options: company,
                  hide: this.state.fromShipment || this.operation.hideShipmentInput === 'no', //appare solo in outbound in triangolazione
                  defaultValue: this.props.companyPrefix,
                  onChange: (value) => {
                    value && this.setState({ companyPrefix: value.description })
                    if (companyValueSelected) this.setState({ companyValueSelected: false })
                    else this.setState({ companyValueSelected: true })
                  },
                },
                {
                  label: __(T.misc.parcel_code_auto),
                  name: 'parcelCode',
                  image: <Icons.Barcode />,
                  focus: initialValues.shipmentCode,
                  hide: this.operation.hideParcelInput === 'yes',
                  onBlur: this.onBlurParcelCode,
                  disabled: companyValueSelected,
                  onChange: () => this.resetDestination(),
                },
                {
                  placeholder: __(T.misc.destination),
                  name: 'destination',
                  required: this.operation.hideDestinationInput === 'no',
                  type: 'select',
                  options: places,
                  config: { value: 'id', label: 'description', secondaryLabel: 'code' },
                  hide: this.state.fromShipment || this.operation.hideDestinationInput === 'yes',
                },
              ]}
              initialValues={initialValues}
              submitText={__(T.misc.next)}
              onSubmit={this.submit}
              width={400}
            />
          </Box>
        </Page.Content>
      </Page>
    )
  }
}
