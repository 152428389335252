import React, { Component } from 'react'
import { Box, Button, Icons, Input, Page, Tab, Table, Section, ParcelSectionHeader } from 'components'
import { getMatchParams, navigate } from 'shared/router'
import { ShipmentParcel, GroupedShipmentProduct } from 'api/types'
import { showToast } from 'shared/utils'
import OutboundShipments from 'api/OutboundShipments'
import { T, __ } from 'translations/i18n'
import RemoteConfig, { OutboundConfig } from 'shared/RemoteConfig'
import ShipmentProvider from 'ShipmentProvider'
import Structures from 'shared/Structures'
import { outboundShipmentBeforeConfirm, outboundShipmentAfterConfirm } from 'pages/_extensions_/OutboundExtensions'

interface State {
  shipment?: ShipmentParcel
  filter: string
  options: any[]
  loading: boolean
  groupedProducts: GroupedShipmentProduct[]
  totalProductsQuantity: number
}

export default class OutboundShipmentDetail extends Component {
  operation = RemoteConfig.getOperationConfig<OutboundConfig>(getMatchParams(this.props).configCode)
  inputRef = React.createRef<HTMLInputElement>()

  state: State = {
    totalProductsQuantity: 0,
    groupedProducts: [],
    filter: '',
    loading: true,
    options: [
      { value: 'parcel', label: __(T.misc.parcel), active: true },
      { value: 'product', label: __(T.misc.product), active: false },
    ],
  }

  async componentDidMount() {
    const { shippingCode } = getMatchParams(this.props)
    try {
      const parcelsResponse = await OutboundShipments.parcelDetail({ shippingCodes: [shippingCode] })
      if (!parcelsResponse || parcelsResponse.length === 0)
        throw new Error(`No parcels for shipping ${shippingCode} found`)

      const groupedProducts: GroupedShipmentProduct[] = ShipmentProvider.processGroupedProducts(parcelsResponse)
      let totalProductsQuantity = 0

      groupedProducts.forEach((product) => {
        totalProductsQuantity += product.detected
      })

      parcelsResponse.forEach((parcel) => (parcel.groupedProducts = ShipmentProvider.processGroupedProducts([parcel])))

      this.setState({
        loading: false,
        shipment: { ...parcelsResponse[0], parcels: parcelsResponse },
        groupedProducts,
        totalProductsQuantity,
      })
    } catch (error) {
      showToast({
        title: __(T.error.error),
        description: error?.message ?? 'Generic error',
        status: 'error',
      })
      this.navigateBack()
    }
  }

  navigateBack = () => navigate('/outbound/:configCode', { configCode: getMatchParams(this.props).configCode })

  setFilter = (filter: string) => this.setState({ filter })

  getFilteredProducts(products: GroupedShipmentProduct[], filter: string) {
    return products.filter(
      (product) =>
        product.product.code.toLowerCase().includes(filter) ||
        product.product.style?.value.toLowerCase().includes(filter) ||
        product.product.material?.value.toLowerCase().includes(filter) ||
        product.product.color?.value.toLowerCase().includes(filter) ||
        product.product.size?.value.toLowerCase().includes(filter)
    )
  }

  changeViews = (option: string) => {
    const { options } = this.state
    options.forEach((opt) => (opt.active = opt.value === option))
    this.inputRef.current!.value! = ''
    this.setState({ options, filter: '' })
  }

  onSearch = () => {}

  newParcel = () => {
    const state = {
      shipmentCode: this.state.shipment?.header?.shippingCode,
      destination: this.state.shipment?.header?.destinationPlace,
      fromShipment: true,
    }
    navigate('/outbound/:configCode/create', { configCode: this.operation?.code }, { state })
  }

  onShipmentConfirm = async () => {
    try {
      const { shipment } = this.state
      if (!shipment) return

      // eslint-disable-next-line prefer-const
      let data: any = {
        configurationId: this.operation.id,
        shippingCode: shipment.header.shippingCode,
      }

      await outboundShipmentBeforeConfirm(shipment, data, this.operation)

      const res = await OutboundShipments.confirmShipment(data)

      await outboundShipmentAfterConfirm(shipment, res, this.operation)

      showToast({ title: __(T.misc.confirmed), status: 'success' })
      navigate('/outbound/:configCode', { configCode: getMatchParams(this.props).configCode })
    } catch (e) {
      showToast({ status: 'error', title: 'Error during shipment confirm', description: e.message })
    }
  }

  onBackPress = () => {
    if (this.operation.hasChecklist === 'no') {
      navigate('/outbound/:configCode/create', { configCode: getMatchParams(this.props).configCode })
    }
    navigate('/outbound/:configCode', { configCode: getMatchParams(this.props).configCode })
  }

  render() {
    const { shipment, options, filter, groupedProducts, totalProductsQuantity, loading } = this.state
    const parcels = shipment?.parcels

    const shippingCode = shipment?.header?.shippingCode
    const parcelsCount = parcels?.length?.toString() ?? '0'
    const destinationPlace = shipment?.header?.destinationPlace?.description

    const opt = options.find((o: any) => o.active).value

    return (
      <Page
        loading={loading}
        title={`${this.operation?.description ?? 'Outbound'}`}
        onBackPress={this.onBackPress}
        header={{
          details: [
            { label: __(T.misc.parcels), value: parcelsCount },
            { label: __(T.misc.shipment), value: shippingCode },
            { label: __(T.misc.destination), value: destinationPlace },
          ],
        }}
        headerRight={
          <Button variant="secondary" size="medium" onClick={this.onShipmentConfirm}>
            {__(T.misc.confirm_shipment)}
          </Button>
        }
      >
        <Page.Content>
          <Box mb={40} row vcenter>
            <Tab
              variant="secondary"
              style={{ border: '2px solid #E0E0E0' }}
              options={options}
              onOptionSelected={this.changeViews}
            />
            <Box ml={20} flex>
              <Input
                placeholder={__(T.placeholder.search_product_parcel)}
                onChange={(f) => this.setState({ filter: f })}
                image={<Icons.Barcode />}
                style={{ width: 350 }}
                onEnter={this.onSearch}
                inputRef={this.inputRef}
              />
            </Box>
            {this.operation.canCreateParcel === 'yes' && (
              <Button onClick={this.newParcel}>{__(T.misc.new_parcel)}</Button>
            )}
          </Box>
          {opt === 'product' && (
            <Table
              headerStyle={{ position: 'relative' }}
              structure={Structures.ShipmentProductStructure(totalProductsQuantity)}
              loading={loading}
              data={groupedProducts?.filter((product) =>
                product.product.code.toLowerCase().includes(filter.toLowerCase())
              )}
              emptyStyle={{ height: 300 }}
            />
          )}
          {opt === 'parcel' &&
            parcels
              ?.filter((parcel) => parcel.header.parcelCode.toLowerCase().includes(filter.toLowerCase()))
              ?.map((parcel) => (
                <Section
                  header={<ParcelSectionHeader parcel={parcel} operation={this.operation} />}
                  body={
                    <Table
                      headerStyle={{ position: 'relative' }}
                      style={{ paddingTop: 0 }}
                      showHeader={false}
                      structure={Structures.ShipmentProductStructure(totalProductsQuantity)}
                      loading={loading}
                      data={parcel.groupedProducts}
                      emptyStyle={{ height: 300 }}
                    />
                  }
                />
              ))}
        </Page.Content>
      </Page>
    )
  }
}
