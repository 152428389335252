// import Encodings from 'api/Encodings'
import AppDatas from 'api/AppDatas'
import OperationConfigurations from 'api/OperationConfigurations'
import { navigate } from 'shared/router'
import { getListFromCommaSeparatedString } from './utils'

// TYPES //////////////////////////////////////////////////////////////////////////////////////////////////////

export type OperationConfigType = 'inbound' | 'outbound' | 'encoding'
export type OperationConfigClient = 'frontend' | 'mobile' | 'station'

// TODO: change Encoding initialType values
export type EncodingInitialType = 'PRODUCT' | 'ORDER' | 'IDENTIFIER' | 'PRODUCTIONORDERROW'

interface OperationConfig {
  id: string
  code: string
  description: string
  operationType: OperationConfigType
  clients: OperationConfigClient[]
  attributes: Record<string, any>
}

export interface ShippingConfig extends OperationConfig {
  readingMode: 'rfid' | 'mixed' | 'barcode' // tmr.operation.config.reading.mode
  canConfirmWithMissing: 'no' | 'yes' | 'withWarning' // tmr.operation.config.missing.confirm
  canConfirmWithOverQuantity: 'no' | 'yes' | 'withWarning' // tmr.operation.config.overqty.confirm
  canConfirmWithUnexpected: 'no' | 'yes' | 'withWarning' // tmr.operation.config.unexpected.confirm
  canForceConfirm: 'no' | 'yes' | 'withExpectedReadings' // tmr.operation.config.force.confirm
  removeMode: 'sku' | 'rfid' | 'epc' | 'none' // tmr.operation.config.remove.mode
  itemWarningStates: string[] // tmr.operation.config.item.warning.states
  itemErrorStates: string[] // tmr.operation.config.item.error.states
  itemIgnoreStates: string[] // tmr.operation.config.item.ignore.states
  itemRequireStates: string[] // tmr.operation.config.item.require.states
  icon?: string
  hasChecklist: 'no' | 'yes'
  hideParcelInput: 'no' | 'yes'
  hideShipmentInput: 'no' | 'yes'
  hideDestinationInput: 'no' | 'yes'
  unknownItemsAction: 'createItemIfProductExist' | 'createItemAndProduct' | 'ignoreItem'
  defaultDestinationPlace?: string
  hierarchyId?: string
  canCreateShipment: 'no' | 'yes'
  canCreateParcel: 'no' | 'yes'
  displayMode: 'groupedByProduct' | 'item'
}

export interface InboundConfig extends ShippingConfig {
  inboundMode?: 'parcelByParcel' | 'multiParcel' | 'shipment' // tmr.operation.config.inbound.mode
  defaultStockZoneCode?: string // tmr.operation.config.inbound.default.stock.zone.code
}

export interface OutboundConfig extends ShippingConfig {
  outboundMode?: 'parcelByParcel' | 'shipment' // tmr.operation.config.outbound.mode
}

export interface EncodingConfig extends OperationConfig {
  initialType?: EncodingInitialType // tmr.logistics.encoding.initial.type
  validateProductionOrder?: boolean // tmr.logistics.encoding.productionorder.validate
  enableWrite?: boolean // tmr.logistics.encoding.write.enable
}

/////////////////////////////////////////////////////////////////////////////////////////////////////////////
export const fakeEncodingOperation = 'FAKE_ENCODING_OPERATION'

export class RemoteConfigBase {
  private operations: OperationConfig[] = []

  defaultTagsBatchInterval?: number

  async load(): Promise<void> {
    try {
      this.fetchAppDatas()
      const operations = await OperationConfigurations.search<OperationConfig>({ client: 'station' })
      this.operations = operations.map(({ operationType, ...rest }) => ({
        operationType: String(operationType).toLowerCase() as OperationConfigType,
        ...rest,
      }))
    } catch (error) {
      console.log(error)
    }
    // Encoding settings removed from 4.53.0
    // const encodingSettings = await Encodings.settings()
    // if (encodingSettings) {
    //   const encodingOpConfig: OperationConfig = {
    //     id: fakeEncodingOperation,
    //     code: fakeEncodingOperation,
    //     description: fakeEncodingOperation,
    //     clients: ['station'],
    //     operationType: 'encoding',
    //     attributes: encodingSettings,
    //   }
    //   this.operations.push(encodingOpConfig)
    // }
  }

  getAllShippingOperations(): ShippingConfig[] {
    return this.operations
      .filter((op) => op.operationType === 'inbound' || op.operationType === 'outbound')
      .map((op) => this.getOperationConfigByCode(op.code) as ShippingConfig)
  }

  getAllEncodingOperations(): EncodingConfig[] {
    return this.operations
      .filter((op) => op.operationType === 'encoding')
      .map((op) => this.getOperationConfigByCode(op.code) as EncodingConfig)
  }

  getOperationConfig<T>(code: string): T {
    return this.getOperationConfigByCode(code) as any
  }

  private getOperationConfigByCode(code: string) {
    const opConfig = this.operations.find((o) => o.code === code)
    if (!opConfig) {
      navigate('/') //TODO navigate messa per retrocompatibilità con AppStore, analizzare come migliorare
      throw new Error(`Unable to find OperationConfig for code: ${code}`)
    }

    if (opConfig.operationType === 'outbound') {
      const mapping: OutboundConfig = {
        ...opConfig,
        readingMode: opConfig.attributes['tmr.operation.config.reading.mode'] ?? 'rfid',
        outboundMode: opConfig.attributes['tmr.operation.config.outbound.mode'] ?? 'parcelByParcel',
        canConfirmWithMissing: opConfig.attributes['tmr.operation.config.missing.confirm'] ?? 'withWarning',
        canConfirmWithOverQuantity: opConfig.attributes['tmr.operation.config.overqty.confirm'] ?? 'withWarning',
        canConfirmWithUnexpected: opConfig.attributes['tmr.operation.config.unexpected.confirm'] ?? 'withWarning',
        displayMode: opConfig.attributes['tmr.operation.config.readings.displayMode'] ?? 'groupedByProduct',
        removeMode: opConfig.attributes['tmr.operation.config.remove.mode'] ?? 'none',
        canForceConfirm: opConfig.attributes['tmr.operation.config.force.confirm'] ?? 'no',
        hideParcelInput: opConfig.attributes['tmr.operation.config.hideParcelInput'] ?? 'no',
        hideShipmentInput: opConfig.attributes['tmr.operation.config.hideShipmentInput'] ?? 'no',
        canCreateShipment: opConfig.attributes['tmr.operation.config.canCreateShipment'] ?? 'yes',
        canCreateParcel: opConfig.attributes['tmr.operation.config.canCreateParcel'] ?? 'yes',
        hideDestinationInput: opConfig.attributes['tmr.operation.config.hideDestinationInput'] ?? 'no',
        itemWarningStates: getListFromCommaSeparatedString(
          opConfig.attributes['tmr.operation.config.item.warning.states']
        ),
        itemErrorStates: getListFromCommaSeparatedString(opConfig.attributes['tmr.operation.config.item.error.states']),
        itemIgnoreStates: getListFromCommaSeparatedString(
          opConfig.attributes['tmr.operation.config.item.ignore.states']
        ),
        itemRequireStates: getListFromCommaSeparatedString(
          opConfig.attributes['tmr.operation.config.item.require.states']
        ),
        icon: opConfig.attributes['tmr.operation.config.custom.icon'],
        hasChecklist: opConfig.attributes['tmr.operation.config.hasChecklist'] ?? 'no',
        defaultDestinationPlace: opConfig.attributes['tmr.otb.operation.config.defaultDestinationPlaceCode'],
        unknownItemsAction:
          opConfig.attributes['tmr.operation.config.unknownItemsAction'] ?? 'createItemIfProductExist',
        hierarchyId: opConfig.attributes['tmr.operation.config.otb.hierarchyId'],
      }
      return mapping
    }

    if (opConfig.operationType === 'inbound') {
      const mapping: InboundConfig = {
        ...opConfig,
        readingMode: opConfig.attributes['tmr.operation.config.reading.mode'] ?? 'rfid',
        inboundMode: opConfig.attributes['tmr.operation.config.inbound.mode'] ?? 'parcelByParcel',
        canConfirmWithMissing: opConfig.attributes['tmr.operation.config.missing.confirm'] ?? 'withWarning',
        canConfirmWithOverQuantity: opConfig.attributes['tmr.operation.config.overqty.confirm'] ?? 'withWarning',
        canConfirmWithUnexpected: opConfig.attributes['tmr.operation.config.unexpected.confirm'] ?? 'withWarning',
        displayMode: opConfig.attributes['tmr.operation.config.readings.displayMode'] ?? 'groupedByProduct',
        removeMode: opConfig.attributes['tmr.operation.config.remove.mode'] ?? 'none',
        canForceConfirm: opConfig.attributes['tmr.operation.config.force.confirm'] ?? 'no',
        hideParcelInput: opConfig.attributes['tmr.operation.config.hideParcelInput'] ?? 'no',
        hideShipmentInput: opConfig.attributes['tmr.operation.config.hideShipmentInput'] ?? 'no',
        canCreateShipment: opConfig.attributes['tmr.operation.config.canCreateShipment'] ?? 'yes',
        canCreateParcel: opConfig.attributes['tmr.operation.config.canCreateParcel'] ?? 'yes',
        hideDestinationInput: opConfig.attributes['tmr.operation.config.hideDestinationInput'] ?? 'no',
        itemWarningStates: getListFromCommaSeparatedString(
          opConfig.attributes['tmr.operation.config.item.warning.states']
        ),
        itemErrorStates: getListFromCommaSeparatedString(opConfig.attributes['tmr.operation.config.item.error.states']),
        itemIgnoreStates: getListFromCommaSeparatedString(
          opConfig.attributes['tmr.operation.config.item.ignore.states']
        ),
        itemRequireStates: getListFromCommaSeparatedString(
          opConfig.attributes['tmr.operation.config.item.require.states']
        ),
        icon: opConfig.attributes['tmr.operation.config.custom.icon'],
        hasChecklist: opConfig.attributes['tmr.operation.config.hasChecklist'] ?? 'yes',
        unknownItemsAction:
          opConfig.attributes['tmr.operation.config.unknownItemsAction'] ?? 'createItemIfProductExist',
        hierarchyId: opConfig.attributes['tmr.operation.config.otb.hierarchyId'],
        defaultStockZoneCode: opConfig.attributes['tmr.operation.config.inbound.default.stock.zone.code'] ?? undefined,
      }
      return mapping
    }

    if (opConfig.operationType === 'encoding') {
      const mapping: EncodingConfig = {
        ...opConfig,
        initialType: opConfig.attributes['tmr.logistics.encoding.initial.type']?.toUpperCase(),
        validateProductionOrder: opConfig.attributes['tmr.logistics.encoding.productionorder.validate']
          ? opConfig.attributes['tmr.logistics.encoding.productionorder.validate']?.toLowerCase() === 'true'
          : true,
        enableWrite: opConfig.attributes['tmr.logistics.encoding.write.enable']
          ? opConfig.attributes['tmr.logistics.encoding.write.enable']?.toLowerCase() === 'true'
          : false,
      }
      return mapping
    }

    throw new Error(`Undefined operationType for OperationConfig with code: ${code}`)
  }

  private fetchAppDatas = async () => {
    const [defaultTagsBatchInterval] = await Promise.all([AppDatas.getValue('defaultTagsBatchInterval')])

    this.defaultTagsBatchInterval = Number.isNaN(Number(defaultTagsBatchInterval))
      ? undefined
      : Number(defaultTagsBatchInterval)
  }
}

export default new RemoteConfigBase()
