import Shipments from 'api/Shipments'
import { GroupedShipmentProduct, ShipmentParcel, TmrItem, TmrPlace, TmrProduct } from 'api/types'
import {
  AntennaButton,
  Box,
  BoxedSwitch,
  Button,
  Icons,
  ItemsStatesRow,
  OperationReadingList,
  Page,
  Spacer,
  TagCounter,
  TextBox,
} from 'components'
import { HeaderDetail } from 'components/Header'
import { outboundParcelAfterConfirm, outboundParcelBeforeConfirm } from 'pages/_extensions_/OutboundExtensions'
import React, { Component } from 'react'
import RemoteConfig, { OutboundConfig } from 'shared/RemoteConfig'
import RfidReader from 'shared/RfidReader'
import { getLocationState, getMatchParams, navigate } from 'shared/router'
import Sounds from 'shared/Sounds'
import { showToast } from 'shared/utils'
import ShipmentProvider, { CheckListType } from 'ShipmentProvider'
import { T, __ } from 'translations/i18n'
import OutboundShipments from '../../api/OutboundShipments'

interface NewPack {
  shipmentCode: string
  parcelCode: string
  destination: TmrPlace
}

interface State {
  newPack?: NewPack
  parcel?: ShipmentParcel
  checkListType: CheckListType
  groupedProducts: GroupedShipmentProduct[]
  loading: boolean
  errorMessage?: string
  deleteMode: boolean
}

export default class OutboundReading extends Component<{}, State> {
  unexpectedFound = false

  operation = RemoteConfig.getOperationConfig<OutboundConfig>(getMatchParams(this.props).configCode)

  showRemoveButton =
    this.operation.removeMode === 'rfid' ||
    (this.operation.removeMode === 'sku' && this.operation.displayMode === 'groupedByProduct')

  state: State = {
    checkListType: 'ITEMS',
    groupedProducts: [],
    loading: true,
    deleteMode: false,
  }

  checkListType!: CheckListType

  async componentDidMount() {
    const { parcelCode } = getMatchParams(this.props)
    const locationState = getLocationState(this.props)
    let parcel
    try {
      if (!this.operation) throw new Error('Operation configuration not found')
      if (this.operation.readingMode !== 'rfid') throw new Error(__(T.error.not_supported_reading_mode))
      if (!parcelCode) {
        parcel = ShipmentProvider.createShipmentParcel(
          locationState.parcelCode,
          locationState.shipmentCode,
          locationState.destination
        )
      }
      const {
        outboundShipmentParcel,
        checkListType,
        groupedProducts,
      } = await ShipmentProvider.fetchOutboundShipmentParcels(
        parcelCode,
        parcel,
        this.operation,
        locationState.destination.id
      )
      await this.initRfidDevice(checkListType)

      this.setState({
        loading: false,
        parcel: outboundShipmentParcel,
        checkListType,
        groupedProducts,
      })
    } catch (err) {
      showToast({
        title: __(T.error.error),
        description: err?.message ?? 'Generic error',
        status: 'error',
      })
      this.navigateBack()
    }
  }

  async initRfidDevice(checkListType) {
    await RfidReader.initialize()

    if (checkListType === 'TAGS') {
      RfidReader.onTagReadCallback = this.handleReceivedTags
    } else {
      RfidReader.onDecodedItemCallback = this.onDecodedItemCallback
      RfidReader.setDecodeFunction(this.decodeFunction)
    }
  }

  decodeFunction = (epcs: string[]) => {
    if (!this.operation) throw new Error('Operation configuration cannot be undefined')

    return Shipments.batchValidate<any>({
      configurationId: this.operation.id,
      identifiers: epcs,
    })
  }

  onDecodedItemCallback = async (itemMap) => {
    const { parcel, groupedProducts, checkListType, deleteMode } = this.state
    let items: TmrItem[] = Object.values<TmrItem>(itemMap)
    if (deleteMode) {
      if (this.operation.removeMode !== 'rfid') return
      items.map((item) => this.removeItemFromReadings(item))
      this.forceUpdate()
      return
    }
    if (this.operation.unknownItemsAction === 'ignoreItem') {
      items = items.filter((itm) => itm && !!itm.id)
    }
    if (this.operation.unknownItemsAction === 'createItemIfProductExist') {
      items = items.filter((itm) => itm && !!itm.product)
    }

    ShipmentProvider.processOutboundItemsStates(items, this.operation)

    items = items.filter(
      (itm) => itm && !itm.__processedStates?.map((state) => state.processedState)?.includes('IGNORE')
    )

    if (checkListType === 'UPCS') {
      ShipmentProvider.processItemForUpcsChecklist(items, [parcel!], groupedProducts)
    } else {
      ShipmentProvider.processItemForItemsChecklist(items, [parcel!], groupedProducts)
    }

    const counters = ShipmentProvider.getCounters(
      parcel,
      checkListType,
      checkListType === 'UPCS' ? 'ITEMS' : checkListType
    )

    if (this.operation.hasChecklist !== 'no' && !this.unexpectedFound && counters.unexpected > 0) {
      this.unexpectedFound = true
      Sounds.error()
    }

    if (this.operation.hasChecklist !== 'no' && counters.detected === counters.expected && counters.unexpected === 0) {
      Sounds.success()
    }

    this.forceUpdate()
  }

  handleReceivedTags = () => {}

  updateUnexpectedFound = () => {
    const { parcel, checkListType } = this.state
    const counters = ShipmentProvider.getCounters(
      parcel,
      checkListType,
      checkListType === 'UPCS' ? 'ITEMS' : checkListType
    )
    if (this.operation.hasChecklist !== 'no' && counters.unexpected > 0) {
      this.unexpectedFound = true
    } else {
      this.unexpectedFound = false
    }
  }

  clear = () => {
    const { parcel } = this.state
    const groupedProducts = ShipmentProvider.clearAllReceivingReadings([parcel!])
    this.unexpectedFound = false
    this.setState({ groupedProducts, errorMessage: undefined })
    RfidReader.clear()
  }

  navigateBack = () => {
    if (this.operation.code === 'outbound-in-triangolazione') {
      navigate('/outbound/:configCode/create', { configCode: this.operation?.code })
      return
    }
    if (this.operation.outboundMode === 'shipment') {
      navigate('/outbound/:configCode/:shippingCode/parcels', {
        configCode: this.operation?.code,
        shippingCode: this.state.parcel?.header?.shippingCode,
      })
      return
    }
    if (this.operation.hasChecklist === 'yes') {
      navigate('/outbound/:configCode', { configCode: this.operation?.code })
    } else {
      navigate('/outbound/:configCode/create', { configCode: this.operation?.code })
    }
  }

  setErrorMessage = (errorMessage: string) => {
    this.setState({ errorMessage })
  }

  confirmOutbound = async () => {
    const { parcel } = this.state
    try {
      if (
        parcel?.detectedItems?.filter((item) =>
          item.__processedStates?.map((state) => state.processedState)?.includes('ERROR')
        ).length
      )
        throw new Error(__(T.error.items_in_error_found))

      if (this.operation.hasChecklist === 'yes') {
        const confirmed = await ShipmentProvider.processShipmentStatus(this.operation, this.setErrorMessage, parcel)
        if (!confirmed) return
      }

      // extension point "beforeConfirm"
      await outboundParcelBeforeConfirm(parcel!, this.operation)

      if (this.operation.code === 'outbound-in-triangolazione') {
        await ShipmentProvider.confirmOutboundTriangolazioni(
          [parcel!],
          this.operation!.id,
          parcel!.header.destinationPlace.code,
          {
            attributes: {
              destinationPlaceId: parcel!.header.destinationPlace.id,
            },
          }
        )
      } else {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        await ShipmentProvider.confirmOutbound([parcel!], this.operation!.id, parcel!.header.destinationPlace.code)
      }
      // extension point "afterConfirm"
      await outboundParcelAfterConfirm(parcel!, this.operation)

      showToast({
        title: __(T.misc.success),
        description: __(T.messages.outbound_success),
        status: 'success',
      })

      this.navigateBack()
    } catch (error) {
      showToast({
        title: __(T.error.error),
        description: error?.message ?? 'Generic error',
        status: 'error',
      })
    }
  }

  suspendOutbound = async () => {
    const { parcel } = this.state
    try {
      if (
        parcel?.detectedItems?.filter((item) =>
          item.__processedStates?.map((state) => state.processedState)?.includes('ERROR')
        ).length
      )
        throw new Error(__(T.error.items_in_error_found))

      if (this.operation.hasChecklist === 'yes') {
        const confirmed = await ShipmentProvider.processShipmentStatus(this.operation, this.setErrorMessage, parcel)
        if (!confirmed) return
      }

      // extension point "beforeConfirm"
      await outboundParcelBeforeConfirm(parcel!, this.operation)

      await ShipmentProvider.suspendOutbound([parcel!], this.operation!.id, parcel!.header.destinationPlace.code)

      // extension point "afterConfirm"
      await outboundParcelAfterConfirm(parcel!, this.operation)

      showToast({
        title: __(T.misc.success),
        description: __(T.messages.outbound_suspend_success),
        status: 'success',
      })

      this.navigateBack()
    } catch (error) {
      showToast({
        title: __(T.error.error),
        description: error?.message ?? 'Generic error',
        status: 'error',
      })
    }
  }

  removeItemFromReadings = async (item: TmrItem) => {
    const { parcel, groupedProducts } = this.state
    const opCode = this.operation.code
    if (!item || item.id === undefined) return
    if (parcel !== undefined && opCode === 'outbound-in-triangolazione') {
      try {
        const shippingRequestParcels = ShipmentProvider.getRemoveShippingRequestParcelsFromShipmentParcels(
          parcel,
          item,
          this.operation.id,
          undefined,
          'TAGS'
        )
        for (let i = 0; i < shippingRequestParcels.length; i++) {
          // eslint-disable-next-line no-await-in-loop
          await OutboundShipments.updateReadings(
            shippingRequestParcels[i].parcelCode,
            shippingRequestParcels[i].shippingParcelDetail
          )
        }
      } catch (error) {
        console.log(error)
      }
    }
    ShipmentProvider.removeItemsForItemsChecklist([item], [parcel!], groupedProducts)
    const itemIdentifiers = item.itemIdentifiers.map((id) => id.code)
    RfidReader.removeTags(itemIdentifiers)
    this.updateUnexpectedFound()
    this.forceUpdate()
  }

  removeProduct = (product: TmrProduct) => {
    const { parcel, groupedProducts } = this.state
    if (!product || product.code === undefined) return
    const allDetectedItems = parcel?.detectedItems ?? []
    const itemsToRemove = allDetectedItems.filter((itm) => itm.productCode === product.code)
    ShipmentProvider.removeItemsForItemsChecklist(itemsToRemove, parcel ? [parcel] : [], groupedProducts)
    const allItemsIdentifiers = itemsToRemove.flatMap((itm) => itm.itemIdentifiers)
    const itemIdentifiers = allItemsIdentifiers.map((id) => id.code)
    RfidReader.removeTags(itemIdentifiers)
    this.updateUnexpectedFound()
    this.forceUpdate()
  }

  onDeleteToggle = (checked: boolean) => {
    const { parcel } = this.state
    if (!parcel) return
    RfidReader.clear()
    if (checked === false) RfidReader.addTags(ShipmentProvider.getShipmentsTags([parcel]))
    this.setState({ deleteMode: checked, errorMessage: undefined })
  }

  render() {
    const { parcel, checkListType, groupedProducts, loading, errorMessage, deleteMode } = this.state
    const shippingCode = parcel?.header.shippingCode
    const parcelCode = parcel?.header.parcelCode
    const destinationPlace = parcel?.header?.destinationPlace?.description || parcel?.header?.destinationPlace?.code
    const { detected, expected, unexpected } = ShipmentProvider.getCounters(
      parcel,
      checkListType,
      checkListType === 'UPCS' ? 'ITEMS' : checkListType
    )

    const details: HeaderDetail[] = []

    if (this.operation.hideShipmentInput !== 'yes') {
      details.push({ label: __(T.misc.shipment), value: shippingCode })
    }

    if (this.operation.hideParcelInput !== 'yes') {
      details.push({ label: __(T.misc.parcel), value: parcelCode })
    }

    if (this.operation.hasChecklist === 'yes') {
      details.push({ label: __(T.misc.destination), value: destinationPlace })
    }

    return (
      <Page
        title={this.operation?.description ?? 'Outbound'}
        onBackPress={this.navigateBack}
        loading={loading}
        header={{
          details,
        }}
        headerRight={
          this.showRemoveButton ? (
            <BoxedSwitch icon={<Icons.Trash fill="black" />} onToggle={this.onDeleteToggle} check={deleteMode} />
          ) : undefined
        }
        enableEmulation
      >
        <Page.Sidebar style={{ overflowY: 'auto' }}>
          <TagCounter detected={detected} expected={expected} unexpected={unexpected} />
          <AntennaButton
            onClear={this.operation.code !== 'outbound-in-triangolazione' ? this.clear : undefined}
            hideClear={detected === 0 && this.operation.code !== 'outbound-in-triangolazione'}
          />
          <Spacer />
          <Box flex />
          <Spacer />
          <TextBox text={errorMessage} type="error" />
          <Spacer />
          <Button
            title={__(T.misc.confirm_parcel)}
            onClick={this.confirmOutbound}
            variant={errorMessage ? 'secondary' : 'primary'}
          />
          <Spacer />
          {this.operation.code === 'outbound-in-triangolazione' && (
            <Button
              title={__(T.misc.continue_later)}
              onClick={this.suspendOutbound}
              variant={errorMessage ? 'secondary' : 'primary'}
            />
          )}
        </Page.Sidebar>

        <Page.Content>
          <ItemsStatesRow
            items={parcel?.detectedItems as TmrItem[]}
            onItemDeleteCallback={
              deleteMode && this.operation.removeMode === 'rfid' ? this.removeItemFromReadings : undefined
            }
          />
          <OperationReadingList
            operation={this.operation}
            items={parcel?.detectedItems as TmrItem[]}
            removeItemCallback={
              deleteMode && this.operation.removeMode === 'rfid' ? this.removeItemFromReadings : undefined
            }
            groupedProducts={groupedProducts}
            removeProductCallback={deleteMode && this.operation.removeMode === 'sku' ? this.removeProduct : undefined}
          />
        </Page.Content>
      </Page>
    )
  }
}
