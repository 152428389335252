import { SessionMovement } from 'api/types'
import TmrBaseResource from './TmrBaseResource'
import api, { responseErrorCheck } from './api'

export default class SessionMovements extends TmrBaseResource {
  static endpoint = '/sessionMovements'

  static async create(data: any) {
    try {
      const response = await api.post<any>(`${this.endpoint}/create`, data)
      return responseErrorCheck<any>(response)
    } catch (e) {
      return undefined
    }
  }

  static async fillItems(pack: SessionMovement) {
    try {
      pack.items = []
      const response = await api.get<any>(`/sessionItems`, { 'sessionMovement.id': pack.id })
      const items = responseErrorCheck<any>(response)
      pack.items = items
    } catch (e) {
      //ignore
    }
  }
}
