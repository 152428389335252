import { createStandaloneToast, UseToastOptions } from '@chakra-ui/react'
import { ModalType, ShipmentParcel, TmrItem, TmrProduct } from 'api/types'
import AppStore from 'AppStore'
import { get, isNumber as isNumberLodash, uniqBy } from 'lodash'
import { v4 as generateUid } from 'uuid'
import Sounds from './Sounds'

const toast = createStandaloneToast()

export const sleep = (ms: number): Promise<void> => {
  return new Promise((resolve) => setTimeout(resolve, ms))
}

export function uuid() {
  return generateUid()
}

// eslint-disable-next-line @typescript-eslint/naming-convention
export const __isDev = process.env.NODE_ENV !== 'production'

interface ToastOptions extends UseToastOptions {
  sound?: boolean
  status: Exclude<UseToastOptions['status'], undefined>
}

export const showToast = ({
  sound = true,
  position = 'bottom-right',
  duration = 3000,
  status,
  isClosable = true,
  ...options
}: ToastOptions) => {
  switch (status) {
    case 'error':
      if (sound) Sounds.error()
      duration = 8000
      break
    case 'success':
      if (sound) Sounds.success()
      break
  }
  return toast({ status, position, duration, isClosable, ...options })
}

export const getCheckListTypes = (shippingDetails: ShipmentParcel[]) => {
  const expectedItems = shippingDetails
    .map((detail) => detail.expectedCounter.items)
    .reduce((acc, curr) => acc + curr, 0)
  const expectedTags = shippingDetails.map((detail) => detail.expectedCounter.tags).reduce((acc, curr) => acc + curr, 0)
  const expectedUpcs = shippingDetails.map((detail) => detail.expectedCounter.upcs).reduce((acc, curr) => acc + curr, 0)
  const checklistTypes: ('ITEMS' | 'TAGS' | 'UPCS')[] = []
  if (expectedItems > 0) {
    checklistTypes.push('ITEMS')
  }
  if (expectedTags > 0) {
    checklistTypes.push('TAGS')
  }
  if (expectedUpcs > 0) {
    checklistTypes.push('UPCS')
  }
  return checklistTypes
}

export function askUserConfirmation(title: string, message: string): Promise<boolean> {
  return new Promise((resolve) => {
    AppStore.showConfirmModal(
      title,
      message,
      () => resolve(true),
      () => resolve(false)
    )
  })
}

export function hideConfirmModal() {
  AppStore.hideConfirmModal()
}

export function openModal(modal: ModalType) {
  AppStore.openModal?.({ id: (+new Date()).toString(), visible: true, ...modal })
}

export function closeModal(id: string) {
  AppStore.closeModal?.(id)
}

export function truncateString(text = '', maxLength: number) {
  if (!text || text.length < maxLength) return text

  return `${text.substring(0, maxLength)}..`
}

export function getDatetime(timestamp?: number) {
  if (!timestamp) return undefined

  const twoDigits = (s) => `0${s}`.slice(-2)

  const date = new Date(timestamp)
  return `${twoDigits(date.getDate())}/${twoDigits(date.getMonth() + 1)}/${date.getFullYear()} ${twoDigits(
    date.getHours()
  )}:${twoDigits(date.getMinutes())}`
}

export function getListFromCommaSeparatedString(text?: string) {
  return text ? text.replace(/\s/g, '').split(',') : []
}

export const uniqueBy = <T>(array: T[], property: string) => uniqBy<T>(array, property)

export const getField = (object: any, path: string) => get(object, path)

export const isNumber = (number: any) => isNumberLodash(number)

export const groupItemsByUpc = (items: TmrItem[]) => {
  const groupedItems: {
    items: TmrItem[]
    product: TmrProduct
    detected: number
  }[] = []
  items.forEach((item) => {
    const upc = item.product?.code ?? item.upc
    const groupedItem = groupedItems.find((itm) => itm.product?.code === upc)
    if (groupedItem) {
      groupedItem.items.push(item)
      groupedItem.detected++
    } else {
      groupedItems.push({ product: { ...item.product, code: upc }, items: [item], detected: 1 })
    }
  })
  return groupedItems
}
