import React from 'react'
import Products from 'api/Products'
import { GroupedShipmentProduct, SessionItem } from 'api/types'
import { Image, SmallTagCounter } from 'components'
import { T, __ } from 'translations/i18n'
import styled from '@emotion/styled'

function ShipmentProductStructure(totalProductsQuantity: number) {
  const ProductImage = styled(Image)`
    object-fit: contain;
    width: 125px;
    height: 100px;
    padding: 10px;
  `
  return [
    {
      label: '',
      width: 140,
      customRender: ({ product }: GroupedShipmentProduct) => (
        <ProductImage style={{ marginTop: 10, marginBottom: 10 }} src={Products.getImageUrl(product.code)} />
      ),
    },
    {
      width: 240,
      label: __(T.misc.upc),
      customValue: ({ product }: GroupedShipmentProduct) => `${product.code}`,
    },
    {
      flex: 2,
      label: __(T.misc.model_part_color_size),
      customValue: ({ product }: GroupedShipmentProduct) =>
        `${product.material?.value ?? '--'} / ${product.style?.value ?? '--'} / ${product.color?.value ?? '--'} / ${
          product.size?.value ?? '--'
        }`,
    },
    {
      label: __(T.misc.quantity, { quantity: totalProductsQuantity }),
      width: 180,
      customRender: (product: GroupedShipmentProduct) => (
        <SmallTagCounter detected={product.detected} unexpected={product.unexpected} expected={product.expected} />
      ),
    },
  ]
}

function InboundCampionarioProductStructure(quantity: any) {
  const ProductImage = styled(Image)`
    object-fit: contain;
    width: 125px;
    height: 100px;
    padding: 10px;
  `

  return [
    {
      label: '',
      width: 140,
      customRender: (item: SessionItem) => (
        <ProductImage style={{ marginTop: 10, marginBottom: 10 }} src={Products.getImageUrl(item.upc)} />
      ),
    },
    {
      label: __(T.misc.upc),
      customValue: (item: SessionItem) => `${item.upc}`,
    },
    {
      label: __(T.misc.total, { quantity }),
      width: 180,
      customRender: (item: SessionItem) => <SmallTagCounter detected={item.quantity} />,
    },
  ]
}

function InboundCampionarioParcelStructure() {
  return [
    {
      label: __(T.misc.upc),
      customValue: (item: SessionItem) => `${item.epc}`,
    },
    {
      label: __(T.misc.quantity),
      width: 180,
      customRender: () => <SmallTagCounter detected={1} />,
    },
  ]
}

export default {
  ShipmentProductStructure,
  InboundCampionarioParcelStructure,
  InboundCampionarioProductStructure,
}
