import api, { responseErrorCheck } from './api'

export default class TmrBaseResource {
  /**
   * resource endpoint eg. /products
   */
  static endpoint

  /**
   * Pagination config (global for all resources)
   */
  static defaultPaginationConfig = {
    offset: 0,
    limit: 50,
    orderBy: 'CREATION_DATE',
    asc: true,
  }

  /**
   * Get one Object based on the search params (eg. id: 435)
   *
   * @param {Object} id entity identifier
   * @returns {Object}
   */
  static get<T>(id, requestConfig = undefined) {
    return api.get<T>(`${this.endpoint}/${id}`, requestConfig).then(responseErrorCheck)
  }

  /**
   * Search resources
   *
   * @param {Object} params query params
   * @returns {Array}
   */
  static search<T>(params = {}, requestConfig = undefined): Promise<T[]> {
    return api.get<T[]>(`${this.endpoint}`, params, requestConfig).then(responseErrorCheck)
  }

  /**
   * Search Resources passing params with pagination config.
   *
   * eg. const parcels = await Parcels.paginate({ received: false });
   *
   * By default the pagination config is:
   * {
   *   offset: 0,
   *   limit: 50,
   *   orderBy: 'CREATION_DATE',
   *   asc: true,
   * }
   *
   * @param {Object} searchParams query params
   * @param {Object} paginationConfig pagination config { offset, limit, groupBy, asc }
   * @returns {Array} for the current page
   */
  static paginate<T>(searchParams, paginationConfig = null, requestConfig = undefined): Promise<T | any[] | any> {
    const paginationParams = paginationConfig || TmrBaseResource.defaultPaginationConfig

    return api
      .get<T | any[]>(`${this.endpoint}`, { ...paginationParams, ...searchParams }, requestConfig)
      .then((res) => res.data)
  }

  /**
   * Total Count for resource
   *
   * @param {Object} params query params
   * @returns {number} total value
   */
  static count(params, requestConfig = undefined): Promise<number> {
    return api.get<any>(`${this.endpoint}/count`, params, requestConfig).then(responseErrorCheck)
  }

  /**
   * Configure pagination for all entities
   *
   * defaultConfig = {
   *   offset: 0,
   *   limit: 25,
   *   orderBy: 'CREATION_DATE',
   *   asc: true,
   * }
   *
   * @param {Object} cfg
   */
  static configureDefaultPagination(cfg, requestConfig = undefined) {
    if ((cfg.hasOwnProperty('offset'), requestConfig)) TmrBaseResource.defaultPaginationConfig.offset = cfg.offset

    if (cfg.hasOwnProperty('limit')) TmrBaseResource.defaultPaginationConfig.limit = cfg.limit

    if (cfg.hasOwnProperty('orderBy')) TmrBaseResource.defaultPaginationConfig.orderBy = cfg.orderBy

    if (cfg.hasOwnProperty('asc')) TmrBaseResource.defaultPaginationConfig.asc = cfg.asc
  }

  /**
   * Creates or Updates an entity
   *
   * @param {Object} data entity parameters
   * @returns {Object}
   */
  static save(data, requestConfig = undefined): Promise<any> {
    return api.post(`${this.endpoint}`, data, requestConfig).then(responseErrorCheck)
  }

  /**
   * Delete a specific entity
   *
   * @param {String} id
   */
  static delete(id, requestConfig = undefined) {
    return api.delete(`${this.endpoint}/${id}`, requestConfig).then(responseErrorCheck)
  }

  /**
   * Creates many entities
   *
   * @param {Array} data array of entities
   * @returns {Array}
   */
  static bulkCreate(data, requestConfig = undefined) {
    return api.post(`${this.endpoint}/bulkCreate`, data, requestConfig).then(responseErrorCheck)
  }

  /**
   * Updates many entities
   *
   * @param {Array} data array of entities to update
   * @returns {Array}
   */
  static bulkUpdate(data, requestConfig = undefined) {
    return api.post(`${this.endpoint}/bulkUpdate`, data, requestConfig).then(responseErrorCheck)
  }
}
