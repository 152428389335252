import React, { Component } from 'react'
import { AntennaBottomControl, AntennaPlaceholder, IdentifierBox } from 'components'
import styled from '@emotion/styled'
import { ProductionOrderRow, ReadIdentifierType, TmrTag } from 'api/types'
import { T, __ } from 'translations/i18n'

interface Props {
  identifiers: ReadIdentifierType[]
  onTagReadCallback?: (tag: TmrTag) => void
  removeErrorTag?: () => void
  placeholder?: any
  productionOrderRow?: ProductionOrderRow
  resetText?: string
}

export default class IdentifiersGrid extends Component<Props> {
  render() {
    const { identifiers, onTagReadCallback, removeErrorTag, placeholder, productionOrderRow, resetText } = this.props

    const idfsGroup = identifiers.reduce((r, a) => {
      r[a.type] = [...(r[a.type] || []), a]
      return r
    }, {})

    if (productionOrderRow) {
      Object.keys(idfsGroup).forEach((type) => {
        if (type !== 'UHFTag' && type !== 'NFCTag') return
        const expetectedAmount =
          productionOrderRow.product.itemConfiguration.identifiers.find((id) => id.type === type)?.amount ?? 0
        if (idfsGroup[type].length > expetectedAmount) {
          idfsGroup[type] = [
            {
              type: type,
              status: 'error',
              errorMessage: __(T.error.too_many_tags_read, {
                exptected: expetectedAmount,
                read: idfsGroup[type].length,
              }),
            },
          ]
        }
      })
    }

    return (
      <>
        {identifiers.length === 0 && (
          <RightContainerPlaceHolder>{placeholder || <AntennaPlaceholder />}</RightContainerPlaceHolder>
        )}
        {identifiers.length > 0 && (
          <RightContainerIdenfiers>
            {Object.keys(idfsGroup)
              .sort((a, b) => {
                if (a === 'NFCTag') return -1
                if (a === 'UHFTag' && b !== 'NFCTag') return -1
                return 0
              })
              .sort((a) => (a === 'CertilogoIdentifier' ? -1 : 1))
              .map((idfKey, index) => {
                return (
                  <IdentifiersContainer key={index} identifiers={idfsGroup[idfKey]}>
                    {idfsGroup[idfKey].map((idf, idfsIndex) => (
                      <IdentifierBox
                        key={idfsIndex}
                        full
                        type={idf.type}
                        status={idf.status}
                        errorMessage={idf.errorMessage}
                      />
                    ))}
                  </IdentifiersContainer>
                )
              })}
          </RightContainerIdenfiers>
        )}
        {!placeholder && (
          <AntennaBottomControl
            resetText={resetText}
            onTagReadCallback={onTagReadCallback}
            resetCallback={removeErrorTag}
          />
        )}
      </>
    )
  }
}

const RightContainerPlaceHolder = styled.div`
  justify-content: center;
  display: flex;
  flex-direction: row;
  align-items: center;
  flex: 3;
`

const IdentifiersContainer = styled.div<{ identifiers: ReadIdentifierType[] }>`
  display: grid;
  grid-gap: 1rem;
  grid-template-columns: ${({ identifiers }) => (identifiers.length === 1 ? '100%' : '49% 49%')};
  grid-template-areas: '${({ identifiers }) => (identifiers.length === 1 ? 'item' : 'item item')}';
  padding-top: 1rem;
  &:first-child {
    padding-top: 0px;
  }
`

const RightContainerIdenfiers = styled.div`
  flex: 1 1 auto;
  height: 0px;
  overflow-y: auto;
`
