import { IdentifierStatus, IdentifierType } from 'api/types'
import React from 'react'
import styled from '@emotion/styled'
import { T, __ } from 'translations/i18n'
import { Box, Icons, Input } from 'components'
import { Spinner } from '@chakra-ui/react'
import RfidReader from 'shared/RfidReader'

const greenColor = '#2FF583'
const redColor = 'rgb(235,87,87,1)'

interface IdentifierBoxProps {
  code?: string
  type?: IdentifierType
  status: IdentifierStatus
  full?: boolean
  errorMessage?: string
}

export default function IdentifierBox({ code, type, status, full, errorMessage }: IdentifierBoxProps) {
  const loader = () => {
    if (status === 'associating') {
      return <Spinner thickness="5px" speed="0.65s" color={greenColor} size="xl" />
    }
    return <></>
  }

  const getTagTypeDescription = () => {
    switch (type) {
      case 'NFCTag':
        return 'TAG NFC'
      case 'UHFTag':
        return 'TAG UHF'
      case 'CertilogoIdentifier':
        return 'Certilogo'
      case 'association':
        return __(T.misc.association)
      case 'write':
        return __(T.misc.association)
      default:
        return `TagType unmatched: ${type}`
    }
  }

  const getStatusDescription = () => {
    switch (status) {
      case 'reading':
        return __(T.misc.reading)
      case 'waiting':
        return __(T.misc.waiting)
      case 'confirmed':
        return __(T.misc.confirmed)
      case 'error':
        return errorMessage || __(T.error.error)
      case 'hidden':
      case 'associating':
        return ''
      default:
        return `Status unmatched: ${status}`
    }
  }

  let Icon
  switch (type) {
    case 'NFCTag':
      Icon = Icons.Nfc
      break
    case 'UHFTag':
      Icon = Icons.Rfid
      break
    case 'CertilogoIdentifier':
      Icon = Icons.Certilogo
      break
    case 'association':
      Icon = Icons.Encoding
      break
    case 'write':
      Icon = Icons.Encoding
      break
    default:
      break
  }

  return (
    <IdentifierBoxStyled full type={type} status={status}>
      <Container>
        {Icon && (
          <Icon style={{ height: 64, width: 64, marginRight: 20 }} fill={status !== 'error' ? '#333333' : 'white'} />
        )}
        {!Icon && <Box height={64} width={64} mr={20} />}
        {!(type === 'CertilogoIdentifier' && status === 'waiting') && (
          <div style={{ flex: 1 }}>
            <TagTypeDescription status={status}>{getTagTypeDescription()}</TagTypeDescription>
            <StatusDescription status={status}>{getStatusDescription()}</StatusDescription>
          </div>
        )}
        {type === 'CertilogoIdentifier' && status === 'waiting' && (
          <div style={{ flex: 1 }}>
            <Input
              startFocus
              image={<Icons.Barcode />}
              placeholder={__(T.placeholder.insert_qr_code)}
              onEnter={(certilogo) => RfidReader.emulateTag({ certilogo })}
            />
          </div>
        )}
        {loader()}
      </Container>
    </IdentifierBoxStyled>
  )
}

const IdentifierBoxStyled = styled.div<{
  status: IdentifierStatus
  type?: IdentifierType
  full?: boolean
}>`
  ${({ status }) => {
    switch (status) {
      case 'confirmed':
        return `
        background-color: ${greenColor};
        border: 2px solid ${greenColor};
        `
      case 'error':
        return `
        background-color: ${redColor};
        border: 2px solid ${redColor};
        color: white;
        `
      default:
        return `
        border: 2px solid #edecec;
        `
    }
  }};
  opacity: ${({ status }) => (status === 'hidden' ? 0.3 : 1)};
  ${({ type, status }) => type === 'CertilogoIdentifier' && status === 'waiting' && `background-color: #EFEFEF;`}
  min-height: 160px;
  display: flex;
  border-radius: 20px;
  padding: 0px 35px;
  box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.08);
`

const Container = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
`

const TagTypeDescription = styled.div<{
  status?: IdentifierStatus
}>`
  ${({ status }) => {
    if (status === 'error') {
      return 'color: white;'
    }
    return ''
  }};
  font-style: normal;
  font-weight: bold;
  font-size: 32px;
  text-transform: uppercase;
`

const StatusDescription = styled.div<{
  status?: IdentifierStatus
}>`
  ${({ status }) => {
    if (status === 'error') {
      return 'color: white;'
    }
    return ''
  }};
  font-weight: 600;
  font-size: 20px;
`
