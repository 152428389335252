import { OperationConfig } from 'api/types'

export const RegisteredRoutes = {
  '/': { component: require('./Dashboard').default },
  '/tag-info': { component: require('./TagInfo/TagInfo').default },
  '/encoding/:configCode': { component: require('./CustomEncoding/CustomEncoding').default },
  '/encoding/:configCode/verify': { component: require('./Encoding/Verify').default },
  '/inbound': { component: require('./Inbound/Inbound').default },
  '/inbound/:configCode': { component: require('./Inbound/Inbound').default },
  '/inbound/:configCode/create': { component: require('./Inbound/InboundCreate').default },
  '/inbound/:configCode/reading/:parcelCode': { component: require('./Inbound/InboundReading').default },
  '/inbound/:configCode/reading/:parcelCode/:inboundZone': { component: require('./Inbound/InboundReading').default },
  '/inbound/:configCode/:shippingCode/parcels': { component: require('./Inbound/InboundShipmentDetail').default },
  '/outbound': { component: require('./Outbound/Outbound').default },
  '/outbound/create': { component: require('./CustomOutbound/CustomOutboundCreate').default },
  '/outbound/:configCode/create': { component: require('./CustomOutbound/CustomOutboundCreate').default },
  '/outbound/:configCode': { component: require('./Outbound/Outbound').default },
  '/outbound/:configCode/reading': { component: require('./Outbound/OutboundReading').default },
  '/outbound/:configCode/reading/:parcelCode': { component: require('./Outbound/OutboundReading').default },
  '/outbound/:configCode/detail/:parcelCode': { component: require('./Outbound/OutboundParcelDetail').default },
  '/outbound/:configCode/:shippingCode/parcels': { component: require('./Outbound/OutboundShipmentDetail').default },
  '/transfer-to-zone': { component: require('./TransferToZone/TransferToZone').default },
  '/transfer-to-zone/reading': { component: require('./TransferToZone/TransferToZoneReading').default },
  '/test': { component: require('./Test').default },
  '/inbound-campionario': { component: require('./InboundCampionario/InboundCampionarioCreate').default },
  '/inbound-campionario/:shippingId': {
    component: require('./InboundCampionario/InboundCampionarioShipmentDetail').default,
  },
  '/inbound-campionario/:shippingId/create': {
    component: require('./InboundCampionario/InboundCampionarioParcelCreate').default,
  },
  '/inbound-campionario/:shippingId/read/:parcelId': {
    component: require('./InboundCampionario/InboundCampionarioParcelRead').default,
  },
  '/items-list/:operationCode': { component: require('./SimpleItemsList/SimpleItemsListReading').default },
}

export const PublicRoutes = {
  '/login': { component: require('./Login').default },
}

export type Routes = keyof typeof RegisteredRoutes | keyof typeof PublicRoutes

export interface PageParams {
  operation?: OperationConfig
}
