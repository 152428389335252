import styled from '@emotion/styled'
import { TmrItem } from 'api/types'
import AppStore from 'AppStore'
import { AntennaButton, BoxedSwitch, GroupedProductList, ItemList, Page, TagCounter } from 'components'
import React, { Component } from 'react'
import { groupItemsByUpc } from 'shared/utils'

interface State {
  items: TmrItem[]
  tag?: TmrItem
  groupByProduct?: boolean
}

export default class TagInfo extends Component<{}, State> {
  state: State = {
    items: [],
    groupByProduct: false,
  }

  async componentDidMount() {
    this.setState({ groupByProduct: await AppStore.getLastSelection('tagInfoGrouped') })
  }

  onItemDecoded = (item: any) => {
    const { items } = this.state
    if (item.id && items.filter((itm) => itm.id === item.id).length > 0) return
    items.unshift(item)
    this.setState({ items })
  }

  clear = () => this.setState({ items: [] })

  render() {
    const { items, groupByProduct } = this.state
    return (
      <Page
        title="TagInfo"
        enableEmulation
        headerRight={
          <BoxedSwitch
            icon={<IconText>GROUP</IconText>}
            check={groupByProduct}
            onToggle={async () =>
              this.setState({
                groupByProduct: await AppStore.setLastSelection('tagInfoGrouped', !groupByProduct),
              })
            }
          />
        }
      >
        <Page.Sidebar>
          <TagCounter detected={items.length} />
          <AntennaButton onClear={this.clear} onItemDecoded={this.onItemDecoded} hideClear={items.length === 0} />
        </Page.Sidebar>

        <Page.Content>
          {groupByProduct && <GroupedProductList data={groupItemsByUpc(items) as any} noChecklist />}
          {!groupByProduct && <ItemList items={items} />}
        </Page.Content>
      </Page>
    )
  }
}
const IconText = styled.p`
  font-weight: bold;
  font-size: 20px;
`
