import React, { Component } from 'react'
import styled from '@emotion/styled'
import { Switch } from '@chakra-ui/react'

interface Props {
  isChecked?: boolean
  onToggle: () => void
  name: string
  label: string
}

export default class CheckBox extends Component<Props> {
  render() {
    const { isChecked, name, onToggle, label } = this.props
    return (
      <>
        <Switch onChange={onToggle} isChecked={isChecked} name={name} id={name} size="lg" />
        <Label htmlFor={name}>{label}</Label>
      </>
    )
  }
}

const Label = styled.label`
  font-weight: 400;
  font-size: 12pt;
  margin-left: 20px;
  color: grey;
`
