import React, { Component } from 'react'
import { GroupedShipmentProduct, TmrItem, TmrProduct } from 'api/types'
import { ShippingConfig } from 'shared/RemoteConfig'
import GroupedProductList from './GroupedProductList'
import ItemList from './ItemList'

interface Props {
  operation: ShippingConfig
  groupedProducts?: GroupedShipmentProduct[]
  items?: TmrItem[]
  removeItemCallback?: (item: TmrItem) => void
  removeProductCallback?: (item: TmrProduct) => void
  loading?: boolean
}

export default class OperationReadingList extends Component<Props> {
  render() {
    const { operation, groupedProducts, removeItemCallback, removeProductCallback, items, loading } = this.props

    if (operation.displayMode === 'groupedByProduct') {
      return (
        <GroupedProductList
          data={groupedProducts ?? []}
          noChecklist={operation.hasChecklist === 'no'}
          onDeleteRowCallback={removeProductCallback}
          loading={loading ?? false}
        />
      )
    }
    if (operation.displayMode === 'item') {
      return <ItemList items={items ?? []} onDeleteRowCallback={removeItemCallback} />
    }
    throw new Error(`Unsupported operation readingsDisplayMode ${operation.displayMode}`)
  }
}
