import React from 'react'
import { Form, Image, LeftHeader, Page } from 'components'
import Auth from 'api/Auth'
import { Container, RightContainer } from 'components/Various'
import { T, __ } from 'translations/i18n'
import { showToast } from 'shared/utils'
import AppStore from 'AppStore'

export default function Login() {
  const onLogin = async (data: any) => {
    try {
      await Auth.login(data.username, data.password)
    } catch (error) {
      showToast({
        title: __(T.error.error),
        description: __(T.error.authentication_failed),
        status: 'error',
      })
      return
    }
    try {
      await AppStore.loadInitalData()
      AppStore.reloadRouting()
    } catch (error) {
      ///ignore
    }
  }

  return (
    <Page title="Login" header={null}>
      <Container>
        <LeftHeader />
        <RightContainer>
          <Image alt="" src="assets/img/logo.png" style={{ marginBottom: 30, width: 200 }} />
          <Form
            style={{ width: 350 }}
            schema={[
              { placeholder: __(T.misc.username), name: 'username', required: true, focus: true },
              { placeholder: __(T.misc.password), name: 'password', type: 'password', required: true },
            ]}
            onSubmit={onLogin}
            submitText={__(T.misc.login)}
          />
        </RightContainer>
      </Container>
    </Page>
  )
}
