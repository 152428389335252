import { TmrItem } from 'api/types'
import { Header } from 'components/Page'
import { navigate } from 'shared/router'
import { T, __ } from 'translations/i18n'
import { confirmTest1 } from './Test1Extensions'
import { confirmTest2 } from './Test2Extensions'

export default class SimpleItemsListProvider {
  static getPageTitle(operationCode: string) {
    switch (operationCode) {
      case 'test-1':
        return 'Test 1'
      case 'test-2':
        return 'Test 2'
      default:
        return __(T.misc.items)
    }
  }

  static getMainActionButtonLabel(operationCode: string) {
    switch (operationCode) {
      case 'test-1':
        return 'Confirm test 1'
      case 'test-2':
        return 'Confirm test 2'
      default:
        return __(T.misc.confirm)
    }
  }

  static getMainAction(operationCode: string, items: TmrItem[], locationState?: any) {
    switch (operationCode) {
      case 'test-1':
        return confirmTest1(items, locationState)
      case 'test-2':
        return confirmTest2(items)
      default:
        return new Promise((resolve, reject) => {
          resolve(true)
        })
    }
  }

  // eslint-disable-next-line consistent-return
  static getOnBackPressAction(operationCode: string) {
    switch (operationCode) {
      case 'test-1':
        return () => {
          console.log('on back test 1')
          navigate('/')
        }
      case 'test-2':
        return () => {
          console.log('on back test 2')
          navigate('/')
        }
    }
  }

  static getPageHeader(operationCode: string, locationState?: any): Header | undefined {
    switch (operationCode) {
      case 'test-1':
        return { details: [{ label: 'Test Label', value: locationState.testParam }] }
      case 'test-2':
        return undefined
      default:
        return undefined
    }
  }
}
