import { TmrItem } from 'api/types'
import { askUserConfirmation, showToast } from 'shared/utils'
import { T, __ } from 'translations/i18n'

export async function confirmTest1(items: TmrItem[], locationState?: any) {
  const confirmed = await askUserConfirmation(__(T.misc.confirm), `Location state: ${JSON.stringify(locationState)}`)
  if (!confirmed) return
  setTimeout(() => {
    showToast({ status: 'success', title: 'Test 1 success' })
  }, 1000)
}
