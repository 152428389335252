import { TmrItem } from 'api/types'
import { ItemList, Modal } from 'components'
import React, { Component } from 'react'
import { T, __ } from 'translations/i18n'

interface Props {
  items: TmrItem[]
  visible?: boolean
  onClose?: () => void
  title?: string
  onDeleteRowCallback?: (item: TmrItem) => void
  itemState?: 'ERROR' | 'WARNING' | 'IGNORE'
}

interface State {
  items: TmrItem[]
}
export default class ItemsListModal extends Component<Props, State> {
  state: State = {
    items: this.props.items,
  }

  onItemDeletePress = (item: TmrItem) => {
    const { onDeleteRowCallback } = this.props
    onDeleteRowCallback && onDeleteRowCallback(item)
    this.setState({ items: this.state.items.filter((itm) => itm.id !== item.id) })
  }

  render() {
    const { visible, onClose, title, itemState, onDeleteRowCallback } = this.props
    const { items } = this.state
    const modalTitle = title ?? __(T.misc.items)

    return (
      <Modal visible={visible} size="4xl" onClose={() => onClose?.()} title={modalTitle}>
        <ItemList
          items={items}
          emptyMessage={__(T.messages.list_empty)}
          onDeleteRowCallback={onDeleteRowCallback ? this.onItemDeletePress : undefined}
          itemState={itemState}
        />
      </Modal>
    )
  }
}
