import Products from 'api/Products'
import { Icons, ImageInfoCard, Input, KeyValueRow, ProgressBar } from 'components'
import { Spacer } from 'components/Various'
import React, { Component } from 'react'
import { __isDev } from 'shared/utils'
import { T, __ } from 'translations/i18n'

interface Props {
  productionOrderRow?: any
  errorProductionOrderRow?: string
  inputEAN?: React.RefObject<HTMLInputElement>
  searchProduct?: (value: string) => void
  clearProduct?: (e?: any) => void
  searchProductionOrderSetting?: any
}

export default class ProductionOrderRowInputBox extends Component<Props> {
  render() {
    const {
      productionOrderRow,
      errorProductionOrderRow,
      inputEAN,
      searchProduct,
      clearProduct,
      searchProductionOrderSetting,
    } = this.props
    if (!productionOrderRow) {
      return (
        <Input
          error={errorProductionOrderRow}
          inputRef={inputEAN}
          defaultValue={__isDev ? 'dev123test' : undefined}
          placeholder={__(T.misc.ean_code)}
          autoFocus
          onEnter={searchProduct}
          barcode
        />
      )
    }
    return (
      <>
        <ImageInfoCard
          onClose={clearProduct}
          title={__(T.misc.ean_code)}
          subtitle={productionOrderRow.product.code}
          image_url={Products.getImageUrl(productionOrderRow.product.code)}
          product={productionOrderRow.product}
        />
        {searchProductionOrderSetting && (
          <>
            <Spacer />
            <div style={{ position: 'relative' }}>
              <KeyValueRow
                label={__(T.misc.associated_items)}
                value={`${productionOrderRow?.encoded || 0} / ${productionOrderRow?.quantity}`}
                image={productionOrderRow?.encoded === productionOrderRow?.quantity ? <Icons.CheckGreen /> : undefined}
              />
              <ProgressBar
                style={{ position: 'absolute', left: 0, right: 0, bottom: 0, backgroundColor: 'transparent' }}
                percentage={((productionOrderRow?.encoded || 0) / (productionOrderRow?.quantity || 0)) * 100}
              />
            </div>
          </>
        )}
      </>
    )
  }
}
