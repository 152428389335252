import React, { Component } from 'react'
import { Form, Page, Box, Icons } from 'components'
import { navigate, getMatchParams } from 'shared/router'
import { T, __ } from 'translations/i18n'
import { showToast } from 'shared/utils'
import { Session, TmrZone } from 'api/types'
import AppStore from 'AppStore'
import Products from 'api/Products'
import SessionMovements from 'api/SessionMovements'
import InboundCampionarioProvider from './InboundCampionarioProvider'

const selectionName = 'campionario-destination'
interface State {
  initialValues?: any
  ddt?: Session
  loading: boolean
}
export default class InboundCampionarioParcelCreate extends Component<{}, State> {
  shippingId = getMatchParams(this.props).shippingId

  state: State = {
    initialValues: {},
    loading: true,
  }

  async componentDidMount() {
    try {
      const { initialValues } = this.state
      const ddt = await InboundCampionarioProvider.retrieveDDT(this.shippingId)
      const zone = await AppStore.getLastSelection<TmrZone>(selectionName)
      if (zone && AppStore.zones?.find((z) => z.id === zone.id)) {
        initialValues.destination = AppStore.zones?.find((z) => z.id === zone.id)
      }
      this.setState({ ddt, loading: false, initialValues })
    } catch (error) {
      showToast({
        title: __(T.error.error),
        description: error?.message ?? 'Generic error',
        status: 'error',
      })
      this.navigateBack()
    }
  }

  navigateBack = () => navigate('/inbound-campionario/:shippingId', { shippingId: this.shippingId })

  submit = async (data: { sku: string; destination: TmrZone }) => {
    const { ddt } = this.state
    const { sku, destination } = data
    try {
      if (sku && destination) {
        await AppStore.setLastSelection(selectionName, destination)
        const product = await Products.search({ code: sku })
        if (product.length !== 1) {
          throw new Error(`Impossibile trovare lo sku "${sku}"`)
        }
        const pack = await SessionMovements.create({
          code: sku,
          session: ddt,
          sessionMovementType: 'TRANSFER_TO_ZONE',
          destinationZone: destination,
          destinationPlace: AppStore.loggedUser?.place,
          items: [],
        })
        if (pack) {
          navigate('/inbound-campionario/:shippingId/read/:parcelId', { shippingId: ddt?.id, parcelId: pack.id })
        } else {
          throw new Error(`Impossibile creare la session movements`)
        }
      } else {
        throw new Error('Selezionare una destinazione')
      }
    } catch (e) {
      showToast({ title: __(T.error.error), status: 'error', description: e.message })
    }
  }

  render() {
    const { initialValues, loading, ddt } = this.state

    return (
      <Page
        loading={loading}
        title="Inbound Campionario"
        onBackPress={this.navigateBack}
        header={{
          details: [
            { label: __(T.misc.parcels), value: (ddt?.packs?.length ?? 0).toString() },
            { label: __(T.misc.shipment), value: ddt?.code },
            { label: __(T.misc.destination), value: ddt?.destinationZone?.description },
          ],
        }}
      >
        <Page.Content bgGrey>
          <Box flex center loading={loading}>
            <Form
              schema={[
                {
                  label: 'Codice Collo (SKU)',
                  name: 'sku',
                  image: <Icons.Barcode />,
                  required: true,
                  focus: true,
                },
                {
                  placeholder: __(T.misc.destination),
                  name: 'destination',
                  required: true,
                  type: 'select',
                  options: AppStore.zones,
                  config: { value: 'id', label: 'description', secondaryLabel: 'code' },
                },
              ]}
              initialValues={initialValues}
              submitText={__(T.misc.next)}
              onSubmit={this.submit}
              width={400}
            />
          </Box>
        </Page.Content>
      </Page>
    )
  }
}
