import OutboundShipments from 'api/OutboundShipments'
import { TmrItem } from 'api/types'
import { showToast } from 'shared/utils'

export async function confirmTest2(items: TmrItem[]) {
  if (!items || items.length === 0) throw new Error('Empty Items list')
  try {
    await OutboundShipments.confirmParcel('TEST-FAKE-PARCEL-NOT-EXISTING')
  } catch (error) {
    showToast({ status: 'error', title: 'Items in Error', description: items.map((itm) => itm.id).join() })
    throw new Error(error)
  }
}
