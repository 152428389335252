import api, { responseErrorCheck } from './api'
import TmrBaseResource from './TmrBaseResource'

export default class Checklist extends TmrBaseResource {
  static endpoint = 'checklistsByUpc'

  static get(parcelCode: string): any {
    return api
      .get<any[]>(`${this.endpoint}?packageCode=${parcelCode}`)
      .then(responseErrorCheck)
      .then((res) => res)
  }
}
