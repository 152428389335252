import React, { Component } from 'react'
import { Page, TagCounter, AntennaButton, ItemList, Box, Button } from 'components'
import { TmrItem } from 'api/types'
import { getLocationState, getMatchParams } from 'shared/router'
import { showToast } from 'shared/utils'
import { T, __ } from 'translations/i18n'
import SimpleItemsListProvider from './SimpleItemsListProvider'

interface State {
  items: TmrItem[]
}

export default class SimpleItemsListReading extends Component<{}, State> {
  operationCode = getMatchParams(this.props).operationCode

  state: State = {
    items: [],
  }

  onItemDecoded = (item) => {
    const { items } = this.state
    items.unshift(item)
    this.setState({ items })
  }

  clear = () => this.setState({ items: [] })

  executeMainAction = async () => {
    try {
      await SimpleItemsListProvider.getMainAction(this.operationCode, this.state.items, getLocationState(this.props))
    } catch (error) {
      showToast({
        title: __(T.error.error),
        description: error?.message ?? 'Generic error',
        status: 'error',
      })
    }
  }

  render() {
    const { items } = this.state
    const title = SimpleItemsListProvider.getPageTitle(this.operationCode)
    const mainButtonLabel = SimpleItemsListProvider.getMainActionButtonLabel(this.operationCode)
    const onBackPress = SimpleItemsListProvider.getOnBackPressAction(this.operationCode)
    const header = SimpleItemsListProvider.getPageHeader(this.operationCode, getLocationState(this.props))

    return (
      <Page title={title} enableEmulation onBackPress={onBackPress} header={header}>
        <Page.Sidebar>
          <TagCounter detected={items.length} />
          <AntennaButton onClear={this.clear} onItemDecoded={this.onItemDecoded} hideClear={items.length === 0} />
          <Box flex />

          <Button title={mainButtonLabel} onClick={this.executeMainAction} />
        </Page.Sidebar>

        <Page.Content>
          <ItemList items={items} />
        </Page.Content>
      </Page>
    )
  }
}
